import {
  BooleanQuestionSection,
  FlowConfig,
  GetProfileConfigResponse,
  Image,
  ProfileDataFormConfig_ConstraintOperator,
  ProfileDataFormConfig_Field,
  ProfileDataFormConfig_FormFieldConstraint,
  ProfileDataFormConfig_ShouldCollect,
  Step,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {EnrollmentStep} from '../../types/flow';
import {
  AppConfig,
  AppImageConfig,
  AppQuestionSectionConfig,
  FormField,
  FormFieldConstraint,
} from '../types/appConfig';
import {
  defaultAccountCreationStep,
  defaultHandoffStep,
  defaultParticipantDataStep,
  doParticipantDataNUX,
  flowConfigStepToEnrollmentStep,
  HandoffStep,
  ONEOF_KIND,
  ParticipantDataStep,
} from '../types/flowConfig';

const protoEnumToFormFieldEnum = (
  protoValue: ProfileDataFormConfig_Field
): FormField => {
  const value: FormField | undefined = new Map<
    ProfileDataFormConfig_Field,
    FormField
  >([
    [ProfileDataFormConfig_Field.FIRST_NAME, 'firstName'],
    [ProfileDataFormConfig_Field.LAST_NAME, 'lastName'],
  ]).get(protoValue);
  if (value === undefined) {
    throw new Error(
      `Got an unexpected Field enum value in a constraint: ${protoValue}`
    );
  }
  return value;
};

// Creates an error message which can be shown when input violates a constraint.
const createErrorMessageForConstraint = (
  constraint: ProfileDataFormConfig_FormFieldConstraint
): string => {
  switch (constraint['operator']) {
    case ProfileDataFormConfig_ConstraintOperator.LENGTH_LESS_THAN: {
      return `This field must be less than ${constraint['value']} characters.`;
    }
    default: {
      throw new Error(
        `Invalid or unrecognized constraint operator in constraint: ${constraint.toString()}`
      );
    }
  }
};

const protoConstraintToFormFieldConstraint = (
  constraint: ProfileDataFormConfig_FormFieldConstraint
): FormFieldConstraint => {
  let predicate: FormFieldConstraint['predicate'];
  switch (constraint['operator']) {
    case ProfileDataFormConfig_ConstraintOperator.LENGTH_LESS_THAN: {
      const bound = parseInt(constraint['value']);
      if (isNaN(bound)) {
        throw new Error(
          `Expected LENGTH_LESS_THAN constraint value "${bound}" to be a number.`
        );
      }
      if (bound <= 0) {
        throw new Error(
          `Expected LENGTH_LESS_THAN constraint value ${bound} to be positive.`
        );
      }
      predicate = (s: string) => s.length < bound;
      break;
    }
    default: {
      throw new Error(
        `Invalid or unrecognized constraint operator in constraint: ${constraint.toString()}`
      );
    }
  }
  return {
    predicate,
    errorMessage:
      constraint['errorMessage'] || createErrorMessageForConstraint(constraint),
  };
};

export const protoToFormFieldConstraints = (
  constraints: ProfileDataFormConfig_FormFieldConstraint[]
): AppConfig['formFieldConstraints'] => {
  const constraintsMap: Map<FormField, FormFieldConstraint[]> = new Map();
  constraints.forEach(
    (constraint: ProfileDataFormConfig_FormFieldConstraint) => {
      const field = protoEnumToFormFieldEnum(constraint['field']);
      if (!constraintsMap.has(field)) {
        constraintsMap.set(field, []);
      }
      constraintsMap
        .get(field)!
        .push(protoConstraintToFormFieldConstraint(constraint));
    }
  );
  return constraintsMap;
};

function protoToQuestionSections(
  responseQuesSecs: BooleanQuestionSection[]
): AppQuestionSectionConfig[] {
  return responseQuesSecs.map(sec => ({
    header: sec['header'],
    subheader: sec['subheader'],
    questions:
      sec['questions'].map(q => ({
        id: q['id'],
        title: q['title'],
        description: q['description'],
      })) || [],
  }));
}

function protoToAppImageConfig(img?: Image): AppImageConfig | undefined {
  return img ? {uri: img['uri'], altText: img['altText']} : undefined;
}

const protoToResearchStudy = (
  response: GetProfileConfigResponse
): string | undefined => {
  return response['associatedResource']?.['linkOneof']?.['oneofKind'] ===
    'researchStudyId'
    ? response['associatedResource']?.['linkOneof']?.['researchStudyId']
    : undefined;
};

const protoToConsentSpecification = (
  response: GetProfileConfigResponse
): AppConfig['consentSpecification'] | undefined => {
  if (!response['consent']) {
    return undefined;
  }
  return {
    consentId: response['consent']?.['consentId'] ?? '',
    consentRevision: response['consent']?.['consentRevision'] ?? 0,
  };
};

function shouldCollectToBool(
  shouldCollect: ProfileDataFormConfig_ShouldCollect
): boolean {
  if (shouldCollect === ProfileDataFormConfig_ShouldCollect.FALSE) {
    return false;
  }
  return true;
}

export const protoToAppConfig = (
  response: GetProfileConfigResponse
): AppConfig => {
  let config: AppConfig = {
    primaryColorMain:
      response['styleConfig']?.['primaryColorMain'] || undefined,
    productName: response['styleConfig']?.['productName'] || undefined,
    pageTitle: response['styleConfig']?.['pageTitle'] || undefined,
    logo1: protoToAppImageConfig(response['styleConfig']?.['logo1']),
    logo2: protoToAppImageConfig(response['styleConfig']?.['logo2']),
    favicon: protoToAppImageConfig(response['styleConfig']?.['favicon']),
    // TODO(PHP-16603): remove hardcoded value.
    appHandoffLink: 'https://bit.ly/48ik86s',
    /*
      response['styleConfig']?.['appHandoffLink'] ||
      response['styleConfig']?.['productHandoffLink'] ||
      '',
     */
    webHandoffLink:
      response['styleConfig']?.['webHandoffLink'] ||
      response['styleConfig']?.['productHandoffLink'] ||
      '',
    // TODO(PHP-16603): remove hardcoded value.
    androidHandoffLink:
      'https://play.google.com/store/apps/details?id=com.verily.me',
    /*
      response['styleConfig']?.['androidHandoffLink'] ||
      response['styleConfig']?.['appHandoffLink'] ||
      response['styleConfig']?.['productHandoffLink'] ||
      '',
     */
    // TODO(PHP-16603): remove hardcoded value.
    iosHandoffLink: 'https://apps.apple.com/app/verily-me/id6448808133',
    /*
      response['styleConfig']?.['iosHandoffLink'] ||
      response['styleConfig']?.['appHandoffLink'] ||
      response['styleConfig']?.['productHandoffLink'] ||
      '',
     */
    disclaimerFooter: response['styleConfig']?.['disclaimerHtml'] || '',
    collectGenderIdentity:
      response['dataFormConfig']?.['collectGenderIdentity'] || false,
    collectAddress: response['dataFormConfig']?.['collectAddress'] || false,
    collectDob:
      shouldCollectToBool(response['dataFormConfig']?.['collectDob']) || false,
    collectEmail:
      shouldCollectToBool(response['dataFormConfig']?.['collectEmail']) ||
      false,
    collectFirstName:
      shouldCollectToBool(response['dataFormConfig']?.['collectFirstName']) ||
      false,
    collectPhone:
      shouldCollectToBool(response['dataFormConfig']?.['collectPhone']) ||
      false,
    collectFirstname:
      shouldCollectToBool(response['dataFormConfig']?.['collectFirstname']) ||
      false,
    collectLastname:
      shouldCollectToBool(response['dataFormConfig']?.['collectLastname']) ||
      false,
    formFieldConstraints: protoToFormFieldConstraints(
      response['dataFormConfig']?.['constraints'] || []
    ),
    eligibility: {
      checkEligibility: response['eligibilityConfig'] ? true : false,
    },
    hasAgreementConsents:
      response.agreementConsentsConfig?.groups.length > 0 ||
      response.policyConfig?.policySets.length > 0,
    consentSpecification: protoToConsentSpecification(response),
    researchStudy: protoToResearchStudy(response),
    useCiam: !response.notInOneverily,

    userType: response.userType,
  };

  // Set customized eligibility ID field label if exists
  if (
    response['eligibilityConfig'] &&
    response['eligibilityConfig']?.['eligKeyLabel']
  ) {
    config.eligibility.keyFieldConfig = {
      keyLabel: response['eligibilityConfig']!['eligKeyLabel']!['label'],
      exactCasing:
        response['eligibilityConfig']!['eligKeyLabel']!['exactCasing'],
    };
  }

  if (response.flowConfig) {
    config = genAppConfigFlow(response.flowConfig, config);
  } else {
    config = genDefaultFlowConfig(config);
  }

  return config;
};

const genAppConfigFlow = (flow: FlowConfig, config: AppConfig): AppConfig => {
  const successMap = new Map<EnrollmentStep, EnrollmentStep>();
  const enrollmentStepToConfigStep = new Map<EnrollmentStep, Step>();
  const hasAccountCreationStep = flow.steps
    .map((s: Step) => s.step.oneofKind)
    .includes(ONEOF_KIND.accountCreation);
  if (hasAccountCreationStep) {
    enrollmentStepToConfigStep.set(
      EnrollmentStep.LOGIN,
      defaultAccountCreationStep
    );
  }
  let firstStep: EnrollmentStep;
  flow.steps.forEach((currentFlowStep: Step, i: number) => {
    extractConfigValuesFromStep(currentFlowStep, config);
    const currentEnrollmentStep = flowConfigStepToEnrollmentStep(
      currentFlowStep,
      config
    );
    if (i === 0) {
      firstStep = currentEnrollmentStep;
    }
    enrollmentStepToConfigStep.set(currentEnrollmentStep, currentFlowStep);
    if (i < flow.steps.length - 1) {
      const nextEnrollmentStep = flowConfigStepToEnrollmentStep(
        flow.steps[i + 1],
        config
      );
      successMap.set(currentEnrollmentStep, nextEnrollmentStep);
    }
  });
  config.flow = {
    firstStep,
    successMap,
    enrollmentStepToConfigStep,
  };
  return config;
};

const extractConfigValuesFromStep = (s: Step, config: AppConfig) => {
  switch (s.step.oneofKind) {
    case ONEOF_KIND.participantData: {
      const step = s.step as ParticipantDataStep;
      const pd = step.participantData;
      config.collectGenderIdentity =
        pd.dataFormConfig?.collectGenderIdentity || false;
      config.collectAddress = pd.dataFormConfig?.collectAddress || false;
      (config.collectDob =
        shouldCollectToBool(pd.dataFormConfig?.collectDob) || false),
        (config.collectPhone =
          shouldCollectToBool(pd.dataFormConfig?.collectPhone) || false),
        (config.collectEmail =
          shouldCollectToBool(pd.dataFormConfig?.collectEmail) || false),
        (config.collectFirstname =
          shouldCollectToBool(pd.dataFormConfig?.collectFirstname) || false),
        (config.collectLastname =
          shouldCollectToBool(pd.dataFormConfig?.collectLastname) || false);
      config.formFieldConstraints = protoToFormFieldConstraints(
        pd.dataFormConfig?.constraints || []
      );
      config.eligibility.checkEligibility = !!pd.eligibilityConfig;
      if (pd.eligibilityConfig && pd.eligibilityConfig?.eligKeyLabel) {
        config.eligibility.keyFieldConfig = {
          keyLabel: pd.eligibilityConfig!.eligKeyLabel!.label,
          exactCasing: pd.eligibilityConfig!.eligKeyLabel!.exactCasing,
        };
      }
      config.hasAgreementConsents =
        config.hasAgreementConsents ||
        pd.agreementConsentsConfig?.groups.length > 0;

      config.participantDataHeader =
        pd.dataFormConfig?.participantDataFormHeader || undefined;
      config.addressHeader =
        pd.dataFormConfig?.addressCollectionSubformHeader || undefined;
      config.participantDataSubheader =
        pd.dataFormConfig?.participantDataFormSubheader || undefined;
      config.contactInfoSubheader =
        pd.dataFormConfig?.contactInfoSubformSubheader || undefined;
      config.addressSubheader =
        pd.dataFormConfig?.addressCollectionSubformSubheader || undefined;
      config.basicInfoSubheader =
        pd.dataFormConfig?.basicInfoSubformSubheader || undefined;

      return;
    }
    case ONEOF_KIND.handoff: {
      const step = s.step as HandoffStep;
      const h = step.handoff;
      config.appHandoffLink = h.appHandoffLink;
      config.webHandoffLink = h.webHandoffLink;
      config.androidHandoffLink = h.androidHandoffLink;
      config.iosHandoffLink = h.iosHandoffLink;
      return;
    }
    default: {
      return;
    }
  }
};

// in the case that we receive a legacy config without a flow specified,
// we will generate a default flow config
export const genDefaultFlowConfig = (config: AppConfig): AppConfig => {
  if (doParticipantDataNUX(config)) {
    config.flow = {
      firstStep: EnrollmentStep.ACCOUNT_CREATION,
      successMap: new Map<EnrollmentStep, EnrollmentStep>([
        [EnrollmentStep.ACCOUNT_CREATION, EnrollmentStep.PARTICIPANT_DATA_NUX],
        [EnrollmentStep.PARTICIPANT_DATA_NUX, EnrollmentStep.HANDOFF],
      ]),
      enrollmentStepToConfigStep: new Map<EnrollmentStep, Step>([
        [EnrollmentStep.ACCOUNT_CREATION, defaultAccountCreationStep],
        [EnrollmentStep.LOGIN, defaultAccountCreationStep],
        [EnrollmentStep.PARTICIPANT_DATA_NUX, defaultParticipantDataStep],
        [EnrollmentStep.HANDOFF, defaultHandoffStep],
      ]),
    };
  } else {
    config.flow = {
      firstStep: EnrollmentStep.ACCOUNT_CREATION,
      successMap: new Map<EnrollmentStep, EnrollmentStep>([
        [EnrollmentStep.ACCOUNT_CREATION, EnrollmentStep.PARTICIPANT_DATA],
        [EnrollmentStep.PARTICIPANT_DATA, EnrollmentStep.HANDOFF],
      ]),
      enrollmentStepToConfigStep: new Map<EnrollmentStep, Step>([
        [EnrollmentStep.ACCOUNT_CREATION, defaultAccountCreationStep],
        [EnrollmentStep.LOGIN, defaultAccountCreationStep],
        [EnrollmentStep.PARTICIPANT_DATA, defaultParticipantDataStep],
        [EnrollmentStep.HANDOFF, defaultHandoffStep],
      ]),
    };
  }
  return config;
};
