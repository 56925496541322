import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {TerminationReason} from '../../components/termination-page/states';
import {useConfig} from '../../hooks/useConfig';
import {UserState} from '../../lib/types/userState';
import {EnrollmentStep, stepToRoute} from '../../types/flow';
import {ROUTE_STUBS} from '../../types/route';

type UseProgressReturn = {
  completeStep: () => void;
  failStep?: (failureReason?: TerminationReason) => void;
};

export function useProgress(step: EnrollmentStep): UseProgressReturn {
  const navigate = useNavigate();
  const {config} = useConfig();

  return {
    completeStep: () => {
      navigate(
        `../${stepToRoute.get(config.flow.successMap.get(step)) ?? '/'}`
      );
    },
    failStep: (failureReason?: TerminationReason) => {
      // Some states don't fit neatly into the original implemented
      // binary success/failure routing so we need to handle them first
      switch (failureReason) {
        case TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED:
          navigate(`../${stepToRoute.get(EnrollmentStep.ALREADY_ENROLLED)}`);
          break;
        case TerminationReason.INELIGIBLE:
          navigate(`../${stepToRoute.get(EnrollmentStep.INELIGIBLE)}`);
          break;
        default:
          navigate(
            `../${
              stepToRoute.get(stateMachineFailure[step]) ?? ROUTE_STUBS.error
            }`
          );
          break;
      }
    },
  };
}

// determine if we should direct the user to the first step or the handoff page
// if the user has already completed enrollment
export function useFirstStep(userState?: UserState) {
  const {config} = useConfig();

  const [step, setStep] = useState<EnrollmentStep>();

  useEffect(() => {
    if (step) {
      return;
    }

    let s = config.flow.firstStep;

    if (userState?.enrollmentComplete) {
      s = config.flow.enrollmentStepToConfigStep?.has(
        EnrollmentStep.REDIRECT_USER
      )
        ? EnrollmentStep.REDIRECT_USER
        : EnrollmentStep.HANDOFF;
    }
    setStep(s);
  }, [
    config.flow.enrollmentStepToConfigStep,
    config.flow.firstStep,
    step,
    userState,
  ]);

  return step;
}

const stateMachineFailure = {
  [EnrollmentStep.PARTICIPANT_DATA]: EnrollmentStep.SCREENED_OUT,
  [EnrollmentStep.PARTICIPANT_DATA_NUX]: EnrollmentStep.SCREENED_OUT,
  [EnrollmentStep.INELIGIBLE]: EnrollmentStep.INELIGIBLE,
  [EnrollmentStep.HANDOFF]: EnrollmentStep.HANDOFF,
  [EnrollmentStep.REDIRECT_USER]: EnrollmentStep.REDIRECT_USER,
  [EnrollmentStep.SCREENED_OUT]: EnrollmentStep.SCREENED_OUT,
};
