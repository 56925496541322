import {yupResolver} from '@hookform/resolvers/yup';
import {useSnackbar} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  EnrolleeEvent,
  GetAgreementConsentsResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {TFunction} from 'i18next';
import {useContext, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {AnalyticsModuleContext} from '../../contexts/analyticsContext';
import {useCheckEligibility} from '../../hooks/useCheckEligibility';
import {useConfig} from '../../hooks/useConfig';
import {useDomain} from '../../hooks/useDomain';
import {useProfile} from '../../hooks/useProfile';
import {useRecordEvent} from '../../hooks/useRecordEvent';
import {useUserState} from '../../hooks/useUserState';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import {getAgreementConsents} from '../../lib/api/getAgreementConsents';
import {parseDateStringToProto, toNameProto} from '../../lib/proto/conversion';
import {FormField, FormFieldConstraint} from '../../lib/types/appConfig';
import {EligibilityStatus} from '../../lib/types/userEligibility';
import {UserState} from '../../lib/types/userState';
import {EnrollmentStep} from '../../types/flow';
import {errorPath} from '../../types/route';
import {
  consentSpecificationIsNullOrEmpty,
  getAddressFromFormValues,
  getDateOfBirth,
  getGenderIdentity,
  numCheckboxesInConsents,
  policiesAgreedTo,
} from '../../utils';
import {useProgress} from '../enrollment-flow/progress';
import Loading from '../loading';
import {getSchema} from '../policy';
import {TerminationReason} from '../termination-page/states';
import ParticipantDataLayout from './layout';
import {isDobWithinConstraints, isZipCodeValid} from './utils';

// Custom error messages shown when schema value is invalid. Assumes a single error
// message for each field.
export type ParticipantDataInvalidValueMessageOverride = {
  missingEligibilityId?: string;
};

// This regex ensures:
// (1) The first digits are not `0` or `1`: (?!0|1)
// (2) The second and third digits are not `11`: (?!\d11)\d{3}
// (3) The last 7 digits are not `4567890`: (?!4567890)
// (4) The last 7 digits are not identical: (?!(\d)\1{6})\d{7}
// Specs derived from https://docs.athenahealth.com/api/workflows/patient-creation
export const phoneRegExp =
  //(1)    (2)          (3)        (4)
  /^(?!0|1)(?!\d11)\d{3}(?!4567890)(?!(\d)\1{6})\d{7}$/;

// Shared by eligibility component which has an additional insurance ID field.
export const createSchema = (
  t: TFunction,
  validationConstraintsByField:
    | Map<FormField, FormFieldConstraint[]>
    | undefined,
  collectGender = true,
  collectEligibilityId = false,
  collectAddress = false,
  collectDob = true,
  collectPhone = true,
  collectEmail = true,
  invalidValueErrorMessages:
    | ParticipantDataInvalidValueMessageOverride
    | undefined = undefined
) => {
  const addressYupObject = yup.object({
    addressLine1: yup
      .string()
      .required(t('address.please-enter-address'))
      .default(''),
    state: yup.string().required(t('address.please-select-state')).default(''),
    city: yup.string().required(t('address.please-enter-city')).default(''),
    zipCode: yup
      .string()
      .required(t('address.please-enter-zip-code'))
      .test('is-valid-zipcode', t('address.invalid-zip-code'), value =>
        isZipCodeValid(value)
      )
      .default(''),
  });

  const eligibilityYupObject = yup.object({
    insurance: yup
      .string()
      .required(invalidValueErrorMessages?.missingEligibilityId)
      .default(''),
  });

  const dateOfBirthYupObject = yup.object({
    dob: yup
      .date()
      .default(null)
      .max(new Date(), t('eligibility.please-enter-your-date-of-birth'))
      .typeError(t('eligibility.please-enter-your-date-of-birth'))
      .required(t('eligibility.please-enter-your-date-of-birth')),
  });

  const phoneYupObject = yup.object({
    // TODO(PHP-17064): Ideally should use a phone number validator library
    // This currently just verifies it's a 10 digit number whose area code
    // does not start with 0
    phone: yup
      .string()
      .trim()
      .length(10, t('eligibility.please-enter-your-ten-digit-phone-number'))
      .matches(
        phoneRegExp,
        t('eligibility.please-enter-your-ten-digit-phone-number')
      )
      .required(t('eligibility.please-enter-your-ten-digit-phone-number'))
      .default(''),
  });

  const emailYupObject = yup.object({
    email: yup
      .string()
      .email(t('eligibility.enter-email-address'))
      .required(t('eligibility.enter-email-address'))
      .default(''),
  });

  // We define this base schema in this function, rather than as a constant
  // external to any function, in order to ensure that i18n.t() is called only
  // from (transitively) inside a React component.
  //
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // This "any" is used because Yup is using some strange conditional types which typescript hates trying to make a union of.
  let participantDataFields: yup.ObjectSchema<any> = yup.object({
    firstName: yup
      .string()
      .required(t('eligibility.please-enter-your-first-name'))
      .default(''),
    lastName: yup
      .string()
      .required(t('eligibility.please-enter-your-last-name'))
      .default(''),
    genderIdentity: yup
      .string()
      // Rather than remove gender identity or mark it as not required when the
      // config does not call for gender data collection, which would affect the
      // shape of the schema in complicated and undesirable ways, we change the
      // range of acceptable values. The value of 0, which corresponds to the
      // proto enum value GENDER_IDENTITY_UNSPECIFIED, is actually an
      // appropriate value if and only if no data is requested.
      .oneOf(collectGender ? ['1', '2', '3', '4', '5', '6', '7'] : ['0'])
      .default('0'),
    ...getSchema().fields,
  });

  participantDataFields = collectEligibilityId
    ? participantDataFields.concat(eligibilityYupObject)
    : participantDataFields;
  participantDataFields = collectAddress
    ? participantDataFields.concat(addressYupObject)
    : participantDataFields;
  participantDataFields = collectDob
    ? participantDataFields.concat(dateOfBirthYupObject)
    : participantDataFields;
  participantDataFields = collectPhone
    ? participantDataFields.concat(phoneYupObject)
    : participantDataFields;
  participantDataFields = collectEmail
    ? participantDataFields.concat(emailYupObject)
    : participantDataFields;
  const schema = participantDataFields.required();

  validationConstraintsByField?.forEach(
    (constraints: FormFieldConstraint[], field: FormField) => {
      yup.reach(schema, field).withMutation((schema: yup.BaseSchema) => {
        constraints.forEach(
          (constraint: FormFieldConstraint, index: number) => {
            schema.test(
              `${field}-test-${index}`,
              constraint.errorMessage ||
                t('error.generic-input-validation-failed'),
              constraint.predicate
            );
          }
        );
      });
    }
  );

  return schema;
};

export default function ParticipantDataPage() {
  const {config} = useConfig();
  const participantDataStep = config.flow.enrollmentStepToConfigStep.get(
    EnrollmentStep.PARTICIPANT_DATA
  );
  const {userState, setParticipantData} = useUserState(participantDataStep);
  const {t} = useTranslation();
  const {checkEligibility} = useCheckEligibility(participantDataStep);
  const {completeStep, failStep} = useProgress(EnrollmentStep.PARTICIPANT_DATA);
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const {recordEvent} = useRecordEvent();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  // If there are consents to load, we should wait for them. If there are none,
  // we don't have to wait at all - all zero consents have been loaded.
  const [consentsAreLoaded, setConsentsAreLoaded] = useState(
    !config.hasAgreementConsents
  );
  const [consents, setConsents] = useState<AgreementConsentContent[]>([]);
  const analyticsModule = useContext(AnalyticsModuleContext);

  function ineligibleUserHandler(): void {
    failStep(TerminationReason.INELIGIBLE);
  }

  function alreadyEnrolledEligibilityPatientHandler(): void {
    failStep(TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED);
  }

  const [modalContinueHandler, setModalContinueHandler] = useState(
    () => ineligibleUserHandler
  );

  useEffect(() => {
    // We should have been routed to participant-data-nux, but something has clearly gone wrong.
    if (!(config.collectPhone && config.collectDob && config.collectEmail)) {
      navigate(errorPath());
    }

    if (!config.hasAgreementConsents) {
      return;
    }
    getAgreementConsents(profileName, domainName, participantDataStep)
      .then((res: GetAgreementConsentsResponse) => {
        setConsents(res.consents);
        setConsentsAreLoaded(true);
      })
      .catch(() => {
        // we have to navigate to a different page here since
        // for some reason the page rerenders and does not allow
        // conditional error element rendering
        // TODO(PHP-15646) - handle this error more gracefully
        // (ideally when preparing agreement consents)
        navigate(errorPath());
      });
  }, [
    profileName,
    domainName,
    config.hasAgreementConsents,
    navigate,
    participantDataStep,
  ]);

  // State and data for the "confirm your info" modal shown
  // for ineligible user.
  const [openModal, setOpenModal] = useState(false);
  // TODO(junjay): Use the EligibilityInfo type here instead
  const [modalConfirmationInfo, setModalConfirmationInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    eligibilityKey: undefined,
  });

  // TODO(junjay): Refactor this logic out of this component
  // This logic simply sets the eligibility key label and its associated
  // invalid value error message with appropriate casing
  const useExactCasingForEligKeyLabel: boolean =
    config.eligibility.keyFieldConfig?.exactCasing || false;
  const eligibilityKeyRefText = config.eligibility.keyFieldConfig?.keyLabel
    ? config.eligibility.keyFieldConfig?.keyLabel
    : t('eligibility.member-id');
  const eligibilityKeyFieldLabel: string = useExactCasingForEligKeyLabel
    ? eligibilityKeyRefText
    : eligibilityKeyRefText.charAt(0).toUpperCase() +
      eligibilityKeyRefText.slice(1);
  const fieldInvalidValueMsgs: ParticipantDataInvalidValueMessageOverride = {
    missingEligibilityId: t('eligibility.please-enter-eligibility-key', {
      eligibilityKeyLabel: useExactCasingForEligKeyLabel
        ? eligibilityKeyRefText
        : eligibilityKeyRefText.charAt(0).toLowerCase() +
          eligibilityKeyRefText.slice(1),
    }),
  };

  const schema = config.hasAgreementConsents
    ? createSchema(
        t,
        config?.formFieldConstraints,
        config?.collectGenderIdentity,
        config.eligibility.checkEligibility,
        config.collectAddress,
        config.collectDob,
        config.collectPhone,
        config.collectEmail,
        fieldInvalidValueMsgs
      ).concat(getSchema())
    : createSchema(
        t,
        config?.formFieldConstraints,
        config.collectGenderIdentity,
        config.eligibility.checkEligibility,
        config.collectAddress,
        config.collectDob,
        config.collectPhone,
        config.collectEmail,
        fieldInvalidValueMsgs
      );

  type SchemaType = yup.InferType<typeof schema>;

  const useFormMethods = useForm<SchemaType>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    // We use values, rather than defaultValues, because the `consents` value is
    // Reactive, so we don't know the "final" value of
    // `numCheckboxesInConsents(consents)` when the component is initially
    // rendered.
    values: {
      ...schema.getDefault(),
      ...userState?.['participantData'],
      ...{
        checkboxReasons: new Array<boolean>(
          numCheckboxesInConsents(consents)
        ).fill(policiesAgreedTo(config, userState)),
      },
    },
  });

  const [currentlyLoading, setCurrentlyLoading] = useState(false);

  const submit = async () => {
    setCurrentlyLoading(true);
    (await analyticsModule)?.default({
      visitorMeta: {enrollment_profile_id: profileName},
    });
    const consentData = config.hasAgreementConsents
      ? {
          policySetConsents: useFormMethods
            .getValues('checkboxReasons')
            ?.map((policy: boolean) => (policy ? new Date() : undefined)),
        }
      : undefined;

    // Exclude the forms policy attribute from being included as
    // part of participant data, as that will be set in consents.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {checkboxReasons, ...formValues} = useFormMethods.getValues();
    const dobISO = new Date(formValues.dob).toISOString();

    if (config.eligibility.checkEligibility) {
      let eligStatus: EligibilityStatus;
      try {
        eligStatus = await checkEligibility({
          name: toNameProto(formValues.firstName, formValues.lastName),
          dob: parseDateStringToProto(getDateOfBirth(dobISO)),
          enrollmentVerificationKey: formValues.insurance,
        });
      } catch (error) {
        if (error instanceof EnrollmentError) {
          switch (error.errorType) {
            case EnrollmentErrorType.MISCONFIGURED_ELIGIBILITY_GROUP:
              snackbar.show(
                t('snackbar.misconfigured-eligibility-group'),
                'error'
              );
              break;
            case EnrollmentErrorType.ELIGIBILITY_USER_ALREADY_ENROLLED:
              setModalContinueHandler(
                () => alreadyEnrolledEligibilityPatientHandler
              );
              setModalConfirmationInfo({
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                dob: getDateOfBirth(dobISO),
                eligibilityKey: formValues.insurance,
              });
              setOpenModal(true);
              break;
            default:
              // For simplicity naively assume all other unhandled errors are transient errors
              snackbar.show(t('snackbar.enroll-submit-error'), 'error');
              break;
          }
        } else {
          snackbar.show(t('snackbar.enroll-submit-error'), 'error');
        }
        setCurrentlyLoading(false);
        return;
      }

      if (!eligStatus.isEligible) {
        setModalContinueHandler(() => ineligibleUserHandler);
        setModalConfirmationInfo({
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          dob: getDateOfBirth(dobISO),
          eligibilityKey: formValues.insurance,
        });
        setCurrentlyLoading(false);
        setOpenModal(true);
        return;
      }
    }

    const participantData: UserState['participantData'] = {
      ...formValues,
      genderIdentity: getGenderIdentity(formValues.genderIdentity),
      dob: getDateOfBirth(dobISO),
      address: config.collectAddress
        ? getAddressFromFormValues(formValues)
        : undefined,
    };
    try {
      await setParticipantData!(participantData, consentData);
    } catch {
      // For simplicity just naively assume all errors are transient submit errors
      // Worth improving specificity in the future.
      snackbar.show(t('snackbar.enroll-submit-error'), 'error');
      setCurrentlyLoading(false);
      return;
    }
    // validate dob with age requirements
    if (!isDobWithinConstraints(new Date(dobISO), {})) {
      setCurrentlyLoading(false);
      // TODO(PHP-6741): implement confirmation modal for age check
      failStep();
      return;
    }

    // TODO(PHP-16217) validate address

    setCurrentlyLoading(false);
    completeStep();
  };

  useEffect(() => {
    recordEvent(
      config.eligibility.checkEligibility
        ? EnrolleeEvent.VISIT_ELIGIBILITY
        : EnrolleeEvent.VISIT_PATIENT_DATA
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (consentsAreLoaded) {
    return (
      <FormProvider {...useFormMethods}>
        <ParticipantDataLayout<SchemaType>
          submit={useFormMethods.handleSubmit(submit)}
          config={config}
          consents={consents}
          econsentHandoff={
            !consentSpecificationIsNullOrEmpty(config.consentSpecification)
          }
          // TODO(junjay): Clean up isLoading- sometimes uses formstate;
          // other times uses isLoading
          isLoading={currentlyLoading}
          policiesAgreedTo={policiesAgreedTo(config, userState)}
          memberIdFieldLabel={eligibilityKeyFieldLabel}
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalConfirmationInfo={modalConfirmationInfo}
          onConfirmModalInfoHandler={modalContinueHandler}
          userType={config.userType}
        />
      </FormProvider>
    );
  } else {
    return <Loading />;
  }
}
