import {Auth} from '@verily-src/auth';
import {
  StartSessionRequest,
  StartSessionResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {callStartSession} from '../../utils/clientCaller';
import {toProfileProto} from '../proto/enrollmentProfile';
import {EnrollmentError, EnrollmentErrorType} from './error';

const sessionServiceException =
  'There was an error starting the session. Please try again later.';
export const startSession = async (
  profileName: string,
  queryParams: URLSearchParams
): Promise<StartSessionResponse> => {
  try {
    const startSessionRequest = StartSessionRequest.create({
      profile: toProfileProto(profileName, window.location.hostname),
      timeZone: utcOffsetMinutesToString(new Date().getTimezoneOffset()),
      queryParams: Array.from(queryParams.keys()).map(key =>
        // figure out if we want to fail more gracefully if
        // there are multiple values for the same query parameter, e.g.
        // "foo.com/bar?baz=1&baz=2". Right now we get only the first value.
        ({key: key, value: queryParams.get(key) || ''})
      ),
    });
    const interceptors = [await Auth.getInterceptor()];
    const res = await callStartSession(startSessionRequest, {
      interceptors,
    });
    return res;
  } catch (error) {
    throw new EnrollmentError(
      EnrollmentErrorType.INTERNAL_SERVER_ERROR,
      sessionServiceException,
      error
    );
  }
};

export const utcOffsetMinutesToString = (utcOffsetMinutes: number) => {
  const sign = utcOffsetMinutes >= 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(utcOffsetMinutes) / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(utcOffsetMinutes) % 60).toString().padStart(2, '0');
  return `${sign}${hours}:${minutes}`;
};
