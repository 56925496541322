import {EnrolleeEvent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {EnrolleeEvent as EnrolleeEventV2} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {useEffect} from 'react';
import {useBundle} from '../../../hooks/useBundle';
import {useConfig} from '../../../hooks/useConfig';
import {useRecordEvent} from '../../../hooks/useRecordEvent';
import {api as apiV2} from '../../../lib/apiv2';
import {EnrollmentStep} from '../../../types/flow';
import Loading from '../../loading';
import {externalRedirect} from './utils';

/**
 * Step in enrollment flow that redirects the user to a URL external to enrollment.
 *
 * This represents an alternative to the `Handoff` page when the user completes enrollment
 * */
export default function RedirectUser() {
  const {config} = useConfig();
  const {recordEvent} = useRecordEvent();

  const redirectUrl = config.flow?.enrollmentStepToConfigStep?.get(
    EnrollmentStep.REDIRECT_USER
  )?.step?.redirectUser?.url;

  useEffect(() => {
    // These should be validated by config service, but run some basic sanity checks
    // in case user gets here unexpectedly
    if (!redirectUrl) {
      throw new Error('Missing redirect URL in profile config');
    }
    const url = new URL(redirectUrl);
    recordEvent(EnrolleeEvent.VISIT_USER_REDIRECT).then(() => {
      // Pass string to function instead of URL object; otherwise tests do not verify passed value correctly
      externalRedirect(url.href);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
}

// Rename and make this default export once non-Verily Me Web enrollment is deprecated
export function RedirectUserVerilyMeWeb() {
  const {advance} = useBundle();

  useEffect(() => {
    apiV2.recordEnrolleeEvent(EnrolleeEventV2.VISIT_USER_REDIRECT).then(() => {
      // This should be the final step in the bundle, so advancing should either
      // bring the user to the bundle's `redirect_url_on_completion` or Verily Me Home.
      advance();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
}
