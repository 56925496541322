import {
  AccountCreationStep,
  GetConfigResponse,
  HandoffStep,
  Image,
  ParticipantDataStep,
  ParticipantDataStep_ConstraintOperator as Operator,
  ParticipantDataStep_Field as Field,
  ParticipantDataStep_FormFieldConstraint as Constraint,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';

export interface AppImageConfig {
  uri: string;
  altText: string;
}

export type CommonConfig = {
  productName?: string;
  logo1?: AppImageConfig;
  logo2?: AppImageConfig;
  disclaimerFooter?: string;
  // TODO(PHP-34807): Add more existing config fields if needed.
};
export type ParticipantDataConfig = {
  collectGender: boolean;
  collectAddress: boolean;
  collectDob: boolean;
  collectFirstName: boolean;
  collectPhone: boolean;
  collectEmail: boolean;
  collectFirstname: boolean;
  collectLastname: boolean;
  formHeader: string;
  formSubHeader: string;
  addressFormHeader: string;
  addressFormSubHeader: string;
  basicInfoSubFormSubHeader: string;
  contactInfoSubFormSubHeader: string;
  constraints: ConstraintConfig[];
};
type ConstraintConfig = {
  field: 'Last Name';
  predicate: (value: string) => boolean;
  errorMessage: string;
};
export type HandoffConfig = {
  appHandoffLink: string;
  webHandoffLink: string;
  androidHandoffLink: string;
  iosHandoffLink: string;
};
export type RedirectConfig = {};
export type AccountCreationConfig = {};

export type Configs = {
  common: CommonConfig;
  participantData?: ParticipantDataConfig;
  accountCreation?: AccountCreationConfig;
  completionHandoff?: HandoffConfig;
  completionRedirect?: RedirectConfig;
};
export const protoToConfigs = (res: GetConfigResponse): Configs => {
  const configs: Configs = {common: protoToCommonConfig(res)};
  switch (res.step?.step.oneofKind) {
    case 'accountCreation': {
      configs.accountCreation = accountCreationStepToConfig(
        (
          res.step.step as {
            oneofKind: string;
            accountCreation: AccountCreationStep;
          }
        ).accountCreation
      );
      break;
    }
    case 'participantData': {
      configs.participantData = participantDataStepToConfig(
        (
          res.step.step as {
            oneofKind: string;
            participantData: ParticipantDataStep;
          }
        ).participantData
      );
      break;
    }
    case 'handoff': {
      configs.completionHandoff = handoffStepToConfig(
        (
          res.step.step as {
            oneofKind: string;
            handoff: HandoffStep;
          }
        ).handoff
      );
      break;
    }
    case 'redirectUser': {
      configs.completionRedirect = {};
      break;
    }
  }
  return configs;
};

function protoToAppImageConfig(img?: Image): AppImageConfig | undefined {
  return img ? {uri: img['uri'], altText: img['altText']} : undefined;
}

const protoToCommonConfig = (res: GetConfigResponse): CommonConfig => {
  return {
    productName: res.styleConfig?.productName,
    logo1: res.styleConfig?.logo1
      ? protoToAppImageConfig(res.styleConfig.logo1)
      : undefined,
    logo2: res.styleConfig?.logo2
      ? protoToAppImageConfig(res.styleConfig.logo2)
      : undefined,
    disclaimerFooter: res.styleConfig?.disclaimerHtml,
  };
};

const accountCreationStepToConfig = (
  _step: AccountCreationStep
): AccountCreationConfig => {
  return {};
};

const participantDataStepToConfig = (
  step: ParticipantDataStep
): ParticipantDataConfig => {
  return {
    collectFirstName: step.collectFirstname,
    collectGender: step.collectGenderIdentity,
    collectAddress: step.collectAddress,
    collectDob: step.collectDob,
    collectPhone: step.collectPhone,
    collectEmail: step.collectEmail,
    collectFirstname: step.collectFirstname,
    collectLastname: step.collectLastname,
    formHeader: step.participantDataFormHeader,
    formSubHeader: step.participantDataFormSubheader,
    addressFormHeader: step.addressCollectionSubformHeader,
    addressFormSubHeader: step.addressCollectionSubformSubheader,
    basicInfoSubFormSubHeader: step.basicInfoSubformSubheader,
    contactInfoSubFormSubHeader: step.contactInfoSubformSubheader,
    constraints: step.constraints.map(constraintToConfig),
  };
};

const constraintToConfig = (constraint: Constraint): ConstraintConfig => {
  return <ConstraintConfig>{
    field: protoFieldToConfigField.get(constraint.field)!,
    predicate: operatorAndValueToPredicate(
      constraint.operator,
      constraint.value
    ),
    errorMessage: constraint.errorMessage,
  };
};

const protoFieldToConfigField = new Map([[Field.LAST_NAME, 'Last Name']]);

const operatorAndValueToPredicate = (
  op: Operator,
  value: string
): ((value: string) => boolean) => {
  switch (op) {
    case Operator.LENGTH_LESS_THAN: {
      return (input: string) => input.length < Number(value);
    }
  }
};

const handoffStepToConfig = (step: HandoffStep): HandoffConfig => {
  return {
    appHandoffLink: step.appHandoffLink,
    webHandoffLink: step.webHandoffLink,
    androidHandoffLink: step.androidHandoffLink,
    iosHandoffLink: step.iosHandoffLink,
  };
};
