import {Grid, Typography, useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {Button, TextField} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  UserType,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import ConsentCheckboxLayout from './subforms/consent-checkbox-layout';

type ParticipantDataLayoutProps = {
  submit: () => void;
  consents: AgreementConsentContent[];
  isLoading?: boolean;
  policiesAgreedTo: boolean;
  userType: UserType;
};

const HeaderLayout = () => {
  return (
    <header>
      <Typography variant="display6" component="h1" display="flex" gutterBottom>
        You’re almost done!
      </Typography>
      <Typography variant="body1">
        Next, carefully read the documents below. <br />
        Then tap <strong>Agree</strong> at the bottom of the screen.
      </Typography>
    </header>
  );
};

const ConsentCardsLayout = (props: {consents: AgreementConsentContent[]}) => {
  return (
    <Stack spacing={1}>
      {props.consents.map((consent, idx) => {
        return ConsentCheckboxLayout(consent, idx);
      })}
    </Stack>
  );
};

const ParticipantDataFormLayout = () => {
  const {control, formState} = useFormContext();
  const {t} = useTranslation();

  return (
    <>
      {!phafConfig.getBoolean('FEATURE_NUX_TOS_ENABLED') && (
        <>
          <Controller
            control={control}
            name={'firstName'}
            render={({field: {ref: _, ...field}, fieldState: {error}}) => (
              <TextField
                required
                data-cy="first-name"
                {...field}
                label={t('patientData.first-name')}
                placeholder={t('patientData.first-name')}
                inputProps={{'aria-label': t('patientData.first-name')}}
                disabled={formState.isSubmitting}
                error={!!error}
                helperText={error?.message}
                fullWidth={true}
                multiline={false}
                style={{
                  marginBottom: error ? undefined : '1.25rem',
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={'lastName'}
            render={({field: {ref: _, ...field}, fieldState: {error}}) => (
              <TextField
                required
                data-cy="last-name"
                {...field}
                label={t('patientData.last-name')}
                placeholder={t('patientData.last-name')}
                inputProps={{'aria-label': t('patientData.last-name')}}
                disabled={formState.isSubmitting}
                error={!!error}
                helperText={error?.message}
                fullWidth={true}
                multiline={false}
                style={{
                  marginTop: '.5rem',
                  marginBottom: error ? undefined : '1.25rem',
                }}
              />
            )}
          />
        </>
      )}
    </>
  );
};

type FooterProps = {
  submit: () => void;
};
const ParticipantDataFooterLayout = (props: FooterProps) => {
  const {formState} = useFormContext();

  return (
    <Stack>
      <div
        style={{
          width: '100%',
          height: '1px',
          position: 'sticky',
          bottom: 0,
          backgroundColor: useTheme().palette.neutral.divider,
        }}
      />
      <Stack sx={{padding: '16px'}}>
        <Typography variant="body2">
          By tapping <strong>Agree</strong>, you accept the terms in the
          documents above.
        </Typography>
        <Button
          data-cy="submit-button"
          data-testid="submit-button"
          label="Agree"
          variant="filled"
          size="large"
          loading={formState.isSubmitting}
          disabled={!formState.isValid}
          onClick={props.submit}
          sx={{mt: 2}}
        />
      </Stack>
    </Stack>
  );
};

const ParticipantDataLayout = ({
  consents,
  submit,
}: ParticipantDataLayoutProps) => {
  return (
    <>
      <Grid
        container
        data-testid="grid"
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          backgroundColor: useTheme().palette.background.paper,
        }}
      >
        <Grid
          item
          key="main-area"
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <form style={{height: '100%'}} onSubmit={submit}>
            <Stack sx={{overflow: 'hidden', height: '100%'}}>
              <Stack
                sx={{
                  overflow: 'hidden',
                  overflowY: 'auto',
                  height: '100%',
                  display: 'inline',
                  padding: '16px',
                }}
                spacing={2}
              >
                <HeaderLayout />
                <Stack spacing={3}>
                  <ConsentCardsLayout consents={consents} />
                  <ParticipantDataFormLayout />
                </Stack>
              </Stack>
              <ParticipantDataFooterLayout submit={submit} />
            </Stack>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ParticipantDataLayout;
