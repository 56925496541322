import {FormControl, Typography} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {SingleSelectDropdown, TextField} from '@verily-src/react-design-system';
import {
  GenderIdentity,
  UserType,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {format as formatDate, isValid, parse} from 'date-fns';
import {enUS as enLocale, es as esLocale} from 'date-fns/locale';
import type {FieldValues, Path} from 'react-hook-form';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {userTypeSpecificI18Namespace} from '../../../i18n_helpers';

type BasicInfoFormProps = {
  collectGenderIdentity: boolean;
  collectDob: boolean;
  collectFirstname: boolean;
  collectLastname: boolean;
  userType: UserType;
  subheader: string;
};

/**
 * Subform layout for collecting user's basic info (name, dob, optionally gender)
 */
export default function BasicInfoFormLayout<T extends FieldValues>({
  collectGenderIdentity,
  collectDob,
  collectFirstname,
  collectLastname,
  userType,
  subheader,
}: BasicInfoFormProps) {
  const {t} = useTranslation();
  const currentLanguage = t('language');

  const {control, formState} = useFormContext<T>();

  const userTypeSpecificContentNs = userTypeSpecificI18Namespace(userType);

  return (
    <>
      {/* Basic Info Header */}
      <p>
        <Typography
          variant="body1em"
          component="h2"
          gutterBottom={
            !(
              subheader ||
              t('user-info-form.basic-info-subheader', {
                ns: userTypeSpecificContentNs,
              })
            )
          }
        >
          {t('user-info-form.basic-info-header', 'Basic Information', {
            ns: userTypeSpecificContentNs,
          })}
        </Typography>
      </p>

      {(subheader ||
        t('user-info-form.basic-info-subheader', '', {
          ns: userTypeSpecificContentNs,
        })) && (
        <Typography variant="caption" gutterBottom>
          {subheader ||
            t('user-info-form.basic-info-subheader', {
              ns: userTypeSpecificContentNs,
            })}
        </Typography>
      )}

      {/* First Name */}
      {collectFirstname && (
        <Controller
          control={control}
          name={'firstName' as Path<T>}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <>
              <TextField
                required
                data-cy="first-name"
                {...field}
                label={t('patientData.first-name')}
                placeholder={t('patientData.first-name')}
                disabled={formState.isSubmitting}
                error={!!error}
                helperText={error?.message}
                style={{width: '70%'}}
                multiline={false}
              />
            </>
          )}
        />
      )}

      {/* Last Name */}
      {collectLastname && (
        <Controller
          control={control}
          name={'lastName' as Path<T>}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <>
              <TextField
                required
                data-cy="last-name"
                {...field}
                label={t('patientData.last-name')}
                aria-labelledby={t('patientData.last-name')}
                placeholder={t('patientData.last-name')}
                disabled={formState.isSubmitting}
                error={!!error}
                helperText={error?.message}
                style={{width: '70%'}}
                multiline={false}
              />
            </>
          )}
        />
      )}

      {/* Date of Birth */}
      {collectDob && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={currentLanguage === 'es' ? esLocale : enLocale}
        >
          <Controller
            control={control}
            name={'dob' as Path<T>}
            render={({
              field: {onChange, onBlur, value},
              fieldState: {error},
            }) => {
              // Convert string "MM/dd/yyyy" -> Date (or null)
              const parsedDate =
                typeof value === 'string' && value
                  ? parse(value, 'MM/dd/yyyy', new Date())
                  : null;

              const handleDateChange = (dateVal: Date | null) => {
                if (dateVal && isValid(dateVal)) {
                  // Convert back to string "MM/dd/yyyy"
                  onChange(formatDate(dateVal, 'MM/dd/yyyy'));
                } else {
                  onChange('');
                }
              };

              return (
                <DatePicker
                  // Display/parse as "MM/dd/yyyy"
                  format="MM/dd/yyyy"
                  value={parsedDate}
                  onChange={handleDateChange}
                  label={t('patientData.date-of-birth')}
                  disabled={formState.isSubmitting}
                  slotProps={{
                    textField: {
                      required: true,
                      inputProps: {
                        'data-cy': 'date-picker',
                      },
                      'aria-labelledby': t('patientData.date-of-birth'),
                      style: {width: '70%'},
                      error: !!error,
                      onBlur: () => onBlur(),
                      multiline: false,
                      helperText: error
                        ? error.message
                        : t('user-info-form.date-of-birth-label', '', {
                            ns: userTypeSpecificContentNs,
                          }),
                    },
                  }}
                />
              );
            }}
          />
        </LocalizationProvider>
      )}

      {/* Gender Identity */}
      {collectGenderIdentity && (
        <Controller
          control={control}
          name={'genderIdentity' as Path<T>}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => {
            const genderIdentityOptions = [
              {
                value: GenderIdentity.MALE.toString(),
                label: t('gender.male'),
              },
              {
                value: GenderIdentity.FEMALE.toString(),
                label: t('gender.female'),
              },
              {
                value: GenderIdentity.NON_BINARY.toString(),
                label: t('gender.non-binary'),
              },
              {
                value: GenderIdentity.TRANSGENDER_MALE.toString(),
                label: t('gender.transgender-male'),
              },
              {
                value: GenderIdentity.TRANSGENDER_FEMALE.toString(),
                label: t('gender.transgender-female'),
              },
              {
                value: GenderIdentity.OTHER.toString(),
                label: t('gender.other'),
              },
              {
                value: GenderIdentity.NON_DISCLOSE.toString(),
                label: t('gender.do-not-wish-to-disclose'),
              },
            ];
            return (
              <FormControl data-cy="gender" required error={!!error}>
                <SingleSelectDropdown
                  labelId="gender-identity-label"
                  id="genderIdentity"
                  data-testid="genderIdentity"
                  label={t('gender.gender-identity')}
                  disabled={formState.isSubmitting}
                  placeholder={t('gender.gender-identity')}
                  renderValue={val => {
                    if (!val) {
                      return (
                        <Typography sx={{color: 'text.muted'}}>
                          {t('gender.gender-identity')}
                        </Typography>
                      );
                    }
                    const selectedOption = genderIdentityOptions.find(
                      option => option.value === val.toString()
                    );
                    return selectedOption ? selectedOption.label : '';
                  }}
                  options={genderIdentityOptions}
                  required={true}
                  formControlSx={{width: '100%'}}
                  style={{
                    width: '70%',
                    borderRadius: '16px',
                  }}
                  error={!!error}
                  helperText={
                    error ? t('eligibility.please-select-gender-identity') : ''
                  }
                  {...field}
                />
              </FormControl>
            );
          }}
        />
      )}
    </>
  );
}
