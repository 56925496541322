/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "enrollment/bff/api/v2/server.proto" (package "enrollment.bffv2", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Empty } from "../../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message enrollment.bffv2.Address
 */
export interface Address {
    /**
     * @generated from protobuf field: repeated string street_address = 1;
     */
    streetAddress: string[];
    /**
     * @generated from protobuf field: string state = 2;
     */
    state: string;
    /**
     * @generated from protobuf field: string city = 3;
     */
    city: string;
    /**
     * @generated from protobuf field: string zip_code = 4;
     */
    zipCode: string;
    /**
     * @generated from protobuf field: string country = 5;
     */
    country: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.Name
 */
export interface Name {
    /**
     * @generated from protobuf field: string family = 1;
     */
    family: string;
    /**
     * @generated from protobuf field: string given = 2;
     */
    given: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.Date
 */
export interface Date {
    /**
     * @generated from protobuf field: int32 year = 1;
     */
    year: number;
    /**
     * @generated from protobuf field: int32 month = 2;
     */
    month: number;
    /**
     * @generated from protobuf field: int32 day = 3;
     */
    day: number;
}
/**
 * @generated from protobuf message enrollment.bffv2.Telecom
 */
export interface Telecom {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.Telecom.ContactPointSystem system = 2;
     */
    system: Telecom_ContactPointSystem;
}
/**
 * @generated from protobuf enum enrollment.bffv2.Telecom.ContactPointSystem
 */
export enum Telecom_ContactPointSystem {
    /**
     * @generated from protobuf enum value: CONTACT_POINT_SYSTEM_UNSPECIFIED = 0;
     */
    CONTACT_POINT_SYSTEM_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: PHONE = 1;
     */
    PHONE = 1,
    /**
     * @generated from protobuf enum value: EMAIL = 2;
     */
    EMAIL = 2
}
/**
 * @generated from protobuf message enrollment.bffv2.ParticipantData
 */
export interface ParticipantData {
    /**
     * @generated from protobuf field: enrollment.bffv2.Name name = 1;
     */
    name?: Name;
    /**
     * @generated from protobuf field: enrollment.bffv2.Date date_of_birth = 2;
     */
    dateOfBirth?: Date;
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.Telecom telecom = 3;
     */
    telecom: Telecom[];
    /**
     * @generated from protobuf field: enrollment.bffv2.GenderIdentity gender_identity = 4;
     */
    genderIdentity: GenderIdentity;
    /**
     * @generated from protobuf field: string enrollment_verification_key = 5;
     */
    enrollmentVerificationKey: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.Address address = 6;
     */
    address?: Address;
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordParticipantDataRequest
 */
export interface RecordParticipantDataRequest {
    /**
     * Identifies with which bundle we expect any session and auth metadata to be
     * associated.
     *
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.ParticipantData data = 2;
     */
    data?: ParticipantData;
    /**
     * @generated from protobuf field: enrollment.bffv2.Step current_step = 3;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordParticipantDataResponse
 */
export interface RecordParticipantDataResponse {
}
/**
 * Generic message type for overriding field labels that appear in forms
 *
 * @generated from protobuf message enrollment.bffv2.FormFieldLabelConfig
 */
export interface FormFieldLabelConfig {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * If true, we never change the casing of the provided value. Otherwise the system
     * adjusts casing as appropriate depending on the context, such as lowercasing the
     * first letter when used within a sentence
     *
     * @generated from protobuf field: bool exact_casing = 2;
     */
    exactCasing: boolean;
}
/**
 * @generated from protobuf message enrollment.bffv2.CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
    /**
     * @generated from protobuf field: string email_address = 2;
     */
    emailAddress: string;
    /**
     * @generated from protobuf field: bool send_password_reset_email = 3;
     */
    sendPasswordResetEmail: boolean;
}
/**
 * @generated from protobuf message enrollment.bffv2.CreateAccountResponse
 */
export interface CreateAccountResponse {
    /**
     * @generated from protobuf field: string password_reset_url = 1;
     */
    passwordResetUrl: string;
    /**
     * @generated from protobuf field: string auth0_id = 2;
     */
    auth0Id: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.GetAgreementConsentsRequest
 */
export interface GetAgreementConsentsRequest {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.Step current_step = 2;
     */
    currentStep?: Step;
}
/**
 * Largely a subset of consent.backend.api.v1's ConsentContent, appropriate for
 * representing a single agreement consent in the Enrollment frontend.
 *
 * @generated from protobuf message enrollment.bffv2.AgreementConsentContent
 */
export interface AgreementConsentContent {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * The order of modules is significant; it is the order in which they will be
     * displayed to the user.
     *
     * @generated from protobuf field: repeated enrollment.bffv2.AgreementConsentContent.Module modules = 2;
     */
    modules: AgreementConsentContent_Module[];
}
/**
 * These types are meant to parallel the HtmlContentModule, LinkModule, and
 * CheckboxModule, respectively.
 *
 * @generated from protobuf message enrollment.bffv2.AgreementConsentContent.Html
 */
export interface AgreementConsentContent_Html {
    /**
     * @generated from protobuf field: string html = 1;
     */
    html: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.AgreementConsentContent.Links
 */
export interface AgreementConsentContent_Links {
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.AgreementConsentContent.Links.Link links = 1;
     */
    links: AgreementConsentContent_Links_Link[];
}
/**
 * @generated from protobuf message enrollment.bffv2.AgreementConsentContent.Links.Link
 */
export interface AgreementConsentContent_Links_Link {
    /**
     * @generated from protobuf field: string text = 1;
     */
    text: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.AgreementConsentContent.Checkbox
 */
export interface AgreementConsentContent_Checkbox {
    /**
     * @generated from protobuf field: repeated string signing_reasons = 1;
     */
    signingReasons: string[];
}
/**
 * @generated from protobuf message enrollment.bffv2.AgreementConsentContent.Module
 */
export interface AgreementConsentContent_Module {
    /**
     * @generated from protobuf oneof: module
     */
    module: {
        oneofKind: "html";
        /**
         * @generated from protobuf field: enrollment.bffv2.AgreementConsentContent.Html html = 1;
         */
        html: AgreementConsentContent_Html;
    } | {
        oneofKind: "links";
        /**
         * @generated from protobuf field: enrollment.bffv2.AgreementConsentContent.Links links = 2;
         */
        links: AgreementConsentContent_Links;
    } | {
        oneofKind: "checkbox";
        /**
         * @generated from protobuf field: enrollment.bffv2.AgreementConsentContent.Checkbox checkbox = 3;
         */
        checkbox: AgreementConsentContent_Checkbox;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bffv2.GetAgreementConsentsResponse
 */
export interface GetAgreementConsentsResponse {
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.AgreementConsentContent consents = 1;
     */
    consents: AgreementConsentContent[];
}
/**
 * @generated from protobuf message enrollment.bffv2.CheckEligibilityRequest
 */
export interface CheckEligibilityRequest {
    /**
     * @generated from protobuf field: enrollment.bffv2.Name name = 1;
     */
    name?: Name;
    /**
     * @generated from protobuf field: enrollment.bffv2.Date date_of_birth = 2;
     */
    dateOfBirth?: Date;
    /**
     * @generated from protobuf field: string action_name = 3;
     */
    actionName: string;
    /**
     * @generated from protobuf field: string enrollment_verification_key = 4;
     */
    enrollmentVerificationKey: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.Step current_step = 5;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bffv2.CheckEligibilityResponse
 */
export interface CheckEligibilityResponse {
    /**
     * @generated from protobuf field: bool is_eligible = 1;
     */
    isEligible: boolean;
}
/**
 * Image shown in enrollment service
 *
 * @generated from protobuf message enrollment.bffv2.Image
 */
export interface Image {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string alt_text = 2;
     */
    altText: string; // Optional
}
/**
 * Styling and Display-Only Configurations
 *
 * @generated from protobuf message enrollment.bffv2.ProfileStyleConfig
 */
export interface ProfileStyleConfig {
    /**
     * Banner logos
     *
     * @generated from protobuf field: enrollment.bffv2.Image logo_1 = 1;
     */
    logo1?: Image;
    /**
     * @generated from protobuf field: enrollment.bffv2.Image logo_2 = 2;
     */
    logo2?: Image; // Optional secondary logo
    /**
     * Optional
     *
     * @generated from protobuf field: enrollment.bffv2.Image favicon = 3;
     */
    favicon?: Image;
    /**
     * Optional disclaimer text shown in content footer. This can include HTML.
     *
     * @generated from protobuf field: string disclaimer_html = 4;
     */
    disclaimerHtml: string;
    /**
     * @generated from protobuf field: string product_name = 5;
     */
    productName: string;
    /**
     * CSS color variable
     *
     * @generated from protobuf field: string primary_color_main = 6;
     */
    primaryColorMain: string;
    /**
     * Document's title shown in browser's title bar or page's tab.
     * If unspecified, we default to "Enrollment" or
     * "ProductName Enrollment" (if product name is provided).
     *
     * @generated from protobuf field: string page_title = 7;
     */
    pageTitle: string;
}
/**
 * will be used to configure eligibility check.
 * currently, existence of message implies eligibility check should occur.
 *
 * @generated from protobuf message enrollment.bffv2.EligibilityConfig
 */
export interface EligibilityConfig {
    /**
     * Optional- customizes the noun referring to the eligibility key in forms/modals.
     * By default enrollment service calls it "Member ID" and adjusts first letter
     * casing based on context
     *
     * @generated from protobuf field: enrollment.bffv2.FormFieldLabelConfig elig_key_label = 1;
     */
    eligKeyLabel?: FormFieldLabelConfig;
    /**
     * Fields to perform eligibility check against -- defaults to all
     *
     * @generated from protobuf field: optional enrollment.bffv2.EligibilityConfig.EligibilityCheckFields elig_check_fields = 2;
     */
    eligCheckFields?: EligibilityConfig_EligibilityCheckFields;
}
/**
 * @generated from protobuf enum enrollment.bffv2.EligibilityConfig.EligibilityCheckFields
 */
export enum EligibilityConfig_EligibilityCheckFields {
    /**
     * All fields: first name, last name, dob, eligibility key
     *
     * @generated from protobuf enum value: ELIGIBILITY_CHECK_FIELDS_UNSPECIFIED = 0;
     */
    ELIGIBILITY_CHECK_FIELDS_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: ALL = 1;
     */
    ALL = 1,
    /**
     * @generated from protobuf enum value: DOB_AND_ELIGIBILITY_KEY = 2;
     */
    DOB_AND_ELIGIBILITY_KEY = 2,
    /**
     * @generated from protobuf enum value: DOB_ONLY = 3;
     */
    DOB_ONLY = 3
}
/**
 * Configuration related to semantic data collection fields that could appear.
 *
 * @generated from protobuf message enrollment.bffv2.ParticipantDataStep
 */
export interface ParticipantDataStep {
    /**
     * @generated from protobuf field: bool collect_gender_identity = 1;
     */
    collectGenderIdentity: boolean;
    /**
     * Optional
     *
     * @generated from protobuf field: bool collect_address = 2;
     */
    collectAddress: boolean;
    /**
     * @generated from protobuf field: enrollment.bffv2.EligibilityConfig eligibility_config = 3;
     */
    eligibilityConfig?: EligibilityConfig;
    /**
     * @generated from protobuf field: enrollment.bffv2.AgreementConsentsConfig agreement_consents_config = 4;
     */
    agreementConsentsConfig?: AgreementConsentsConfig;
    /**
     * Optionally change the header and subheader for the participant data collection page:
     *
     * @generated from protobuf field: string participant_data_form_header = 5;
     */
    participantDataFormHeader: string;
    /**
     * @generated from protobuf field: string participant_data_form_subheader = 6;
     */
    participantDataFormSubheader: string;
    /**
     * Optionally change the header and subheader for the address collection section; won't be rendered if address collection is not enabled:
     *
     * @generated from protobuf field: string address_collection_subform_header = 7;
     */
    addressCollectionSubformHeader: string;
    /**
     * @generated from protobuf field: string address_collection_subform_subheader = 8;
     */
    addressCollectionSubformSubheader: string;
    /**
     * Optionally change the subheader for the basic information section:
     *
     * @generated from protobuf field: string basic_info_subform_subheader = 9;
     */
    basicInfoSubformSubheader: string;
    /**
     * Optionally change the subheader for the contact information section:
     *
     * @generated from protobuf field: string contact_info_subform_subheader = 10;
     */
    contactInfoSubformSubheader: string;
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.ParticipantDataStep.FormFieldConstraint constraints = 11;
     */
    constraints: ParticipantDataStep_FormFieldConstraint[];
    /**
     * @generated from protobuf field: bool collect_phone = 12;
     */
    collectPhone: boolean;
    /**
     * @generated from protobuf field: bool collect_email = 13;
     */
    collectEmail: boolean;
    /**
     * @generated from protobuf field: bool collect_dob = 14;
     */
    collectDob: boolean;
}
/**
 * Defines a constraint on a form field.
 * Example:
 *     field: FIRST_NAME
 *     operator: LENGTH_LESS_THAN
 *     value: "21"
 *     error_message: "We can't handle names of more than 20 characters."
 *
 * @generated from protobuf message enrollment.bffv2.ParticipantDataStep.FormFieldConstraint
 */
export interface ParticipantDataStep_FormFieldConstraint {
    /**
     * @generated from protobuf field: enrollment.bffv2.ParticipantDataStep.Field field = 1;
     */
    field: ParticipantDataStep_Field; // Must be a non-default value.
    /**
     * @generated from protobuf field: enrollment.bffv2.ParticipantDataStep.ConstraintOperator operator = 2;
     */
    operator: ParticipantDataStep_ConstraintOperator; // Must be a non-default value.
    /**
     * May be interpreted as a non-string data type, depending on the
     * ConstraintOperator. May not be required by all ConstraintOperators.
     *
     * @generated from protobuf field: string value = 3;
     */
    value: string;
    /**
     * Optional. Shown to the user if this constraint fails. Will be shown
     * literally; should not include substitution strings of any kind.
     *
     * @generated from protobuf field: string error_message = 4;
     */
    errorMessage: string;
}
/**
 * @generated from protobuf enum enrollment.bffv2.ParticipantDataStep.Field
 */
export enum ParticipantDataStep_Field {
    /**
     * @generated from protobuf enum value: FIELD_UNSPECIFIED = 0;
     */
    FIELD_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: FIRST_NAME = 1;
     */
    FIRST_NAME = 1,
    /**
     * @generated from protobuf enum value: LAST_NAME = 2;
     */
    LAST_NAME = 2
}
/**
 * @generated from protobuf enum enrollment.bffv2.ParticipantDataStep.ConstraintOperator
 */
export enum ParticipantDataStep_ConstraintOperator {
    /**
     * @generated from protobuf enum value: CONSTRAINT_OPERATOR_UNSPECIFIED = 0;
     */
    CONSTRAINT_OPERATOR_UNSPECIFIED = 0,
    /**
     * Value will be interpreted as an integer.
     *
     * @generated from protobuf enum value: LENGTH_LESS_THAN = 1;
     */
    LENGTH_LESS_THAN = 1
}
/**
 * @generated from protobuf message enrollment.bffv2.GetConfigRequest
 */
export interface GetConfigRequest {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.GetConfigResponse
 */
export interface GetConfigResponse {
    /**
     * @generated from protobuf field: enrollment.bffv2.ProfileStyleConfig style_config = 1;
     */
    styleConfig?: ProfileStyleConfig;
    /**
     * @generated from protobuf field: enrollment.bffv2.UserType user_type = 2;
     */
    userType: UserType;
    /**
     * Current enrollment step associated with the user's plan definition
     *
     * @generated from protobuf field: enrollment.bffv2.Step step = 3;
     */
    step?: Step;
}
/**
 * @generated from protobuf message enrollment.bffv2.Step
 */
export interface Step {
    /**
     * @generated from protobuf oneof: step
     */
    step: {
        oneofKind: "accountCreation";
        /**
         * @generated from protobuf field: enrollment.bffv2.AccountCreationStep account_creation = 1;
         */
        accountCreation: AccountCreationStep;
    } | {
        oneofKind: "participantData";
        /**
         * @generated from protobuf field: enrollment.bffv2.ParticipantDataStep participant_data = 2;
         */
        participantData: ParticipantDataStep;
    } | {
        oneofKind: "handoff";
        /**
         * Successful enrollment should end with handoff page or redirect to somewhere else
         *
         * @generated from protobuf field: enrollment.bffv2.HandoffStep handoff = 3;
         */
        handoff: HandoffStep;
    } | {
        oneofKind: "redirectUser";
        /**
         * @generated from protobuf field: enrollment.bffv2.RedirectUserStep redirect_user = 4;
         */
        redirectUser: RedirectUserStep;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bffv2.AccountCreationStep
 */
export interface AccountCreationStep {
}
/**
 * Enrollment step representing user completion that shows the product handoff page
 *
 * @generated from protobuf message enrollment.bffv2.HandoffStep
 */
export interface HandoffStep {
    /**
     * URL for product handoff to app store via QR code. This link should be
     * app-store agnostic and when scanned by a QR scanner, be able to
     * direct the user to their device's app store
     *
     * @generated from protobuf field: string app_handoff_link = 1;
     */
    appHandoffLink: string;
    /**
     * URL for product handoff to web app
     *
     * @generated from protobuf field: string web_handoff_link = 2;
     */
    webHandoffLink: string;
    /**
     * Optional URLs for product handoff to specific app stores. They will
     * supersede  app_handoff_link for their respective "Get it on ..." buttons.
     * If one or both of these are provided, app_handoff_link must be provided, as
     * even with store-specific links, a generic link is still necessary to
     * generate a store-agnostic QR code.
     *
     * @generated from protobuf field: string android_handoff_link = 3;
     */
    androidHandoffLink: string;
    /**
     * @generated from protobuf field: string ios_handoff_link = 4;
     */
    iosHandoffLink: string;
}
/**
 * Enrollment step representing user redirection to somewhere else.
 * Currently this is expected to be used in lieu of HandoffStep as the final completion step
 * for flows that desire this. The URL is determined by the Bundle's redirect_url_on_completion
 *
 * @generated from protobuf message enrollment.bffv2.RedirectUserStep
 */
export interface RedirectUserStep {
}
/**
 * used to determine which Consent to give user
 *
 * @generated from protobuf message enrollment.bffv2.ConsentSpecification
 */
export interface ConsentSpecification {
    /**
     * @generated from protobuf field: string consent_id = 1;
     */
    consentId: string;
    /**
     * @generated from protobuf field: int32 consent_revision = 2;
     */
    consentRevision: number;
}
/**
 * Refers to one or more agreement consents from the Consent service.
 * Should not be used for informed consents.
 *
 * @generated from protobuf message enrollment.bffv2.AgreementConsentsConfig
 */
export interface AgreementConsentsConfig {
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.AgreementConsentsConfig.AgreementConsentsGroup groups = 1;
     */
    groups: AgreementConsentsConfig_AgreementConsentsGroup[];
}
/**
 * @generated from protobuf message enrollment.bffv2.AgreementConsentsConfig.AgreementConsent
 */
export interface AgreementConsentsConfig_AgreementConsent {
    /**
     * @generated from protobuf field: enrollment.bffv2.ConsentSpecification consent = 1;
     */
    consent?: ConsentSpecification;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.AgreementConsentsConfig.AgreementConsentsGroup
 */
export interface AgreementConsentsConfig_AgreementConsentsGroup {
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.AgreementConsentsConfig.AgreementConsent consents = 1;
     */
    consents: AgreementConsentsConfig_AgreementConsent[];
}
/**
 * @generated from protobuf message enrollment.bffv2.CompleteEnrollmentRequest
 */
export interface CompleteEnrollmentRequest {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.CompleteEnrollmentResponse
 */
export interface CompleteEnrollmentResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordEnrolleeEventRequest
 */
export interface RecordEnrolleeEventRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp observed_at = 1;
     */
    observedAt?: Timestamp;
    /**
     * IANA time zone, e.g. "America/New_York"
     *
     * @generated from protobuf field: string observed_time_zone = 2;
     */
    observedTimeZone: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.EnrolleeEvent event = 3;
     */
    event: EnrolleeEvent;
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordEnrolleeEventResponse
 */
export interface RecordEnrolleeEventResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordUTMRequest
 */
export interface RecordUTMRequest {
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.RecordUTMRequest.UTMTag utm_tags = 1;
     */
    utmTags: RecordUTMRequest_UTMTag[];
    /**
     * @generated from protobuf field: string action_name = 2;
     */
    actionName: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordUTMRequest.UTMTag
 */
export interface RecordUTMRequest_UTMTag {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.RecordUTMResponse
 */
export interface RecordUTMResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.StartEnrollmentRequest
 */
export interface StartEnrollmentRequest {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.StartEnrollmentResponse
 */
export interface StartEnrollmentResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.GetUserStateRequest
 */
export interface GetUserStateRequest {
}
/**
 * @generated from protobuf message enrollment.bffv2.GetUserStateResponse
 */
export interface GetUserStateResponse {
    /**
     * @generated from protobuf field: enrollment.bffv2.ParticipantData participant_data = 1;
     */
    participantData?: ParticipantData;
    /**
     * @generated from protobuf field: bool is_complete = 5;
     */
    isComplete: boolean;
    /**
     * (Patient ID From https://www.hl7.org/fhir/resource.html), valid and unique
     * only within a specific FHIR store
     *
     * @generated from protobuf field: string patient_id = 6;
     */
    patientId: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.StartSessionRequest
 */
export interface StartSessionRequest {
    /**
     * @generated from protobuf field: string action_name = 4;
     */
    actionName: string;
    /**
     * @generated from protobuf field: repeated enrollment.bffv2.StartSessionRequest.QueryParam query_params = 1;
     */
    queryParams: StartSessionRequest_QueryParam[];
    /**
     * The ISO8601 formatted user's time zone relative to UTC (±hh:mm). We set the
     * time zone here because we expect the session scope to be short-lived and
     * the user's time zone to be relatively stable within it.
     *
     * @generated from protobuf field: string time_zone = 2;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.StartSessionRequest.QueryParam
 */
export interface StartSessionRequest_QueryParam {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.StartSessionResponse
 */
export interface StartSessionResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.ReportErrorRequest
 */
export interface ReportErrorRequest {
    /**
     * @generated from protobuf field: string location = 1;
     */
    location: string;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * @generated from protobuf field: string stack = 3;
     */
    stack: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.ReportErrorResponse
 */
export interface ReportErrorResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.CheckAuthenticationRequest
 */
export interface CheckAuthenticationRequest {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
    /**
     * @generated from protobuf field: enrollment.bffv2.Step current_step = 2;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bffv2.CheckAuthenticationResponse
 */
export interface CheckAuthenticationResponse {
}
/**
 * @generated from protobuf message enrollment.bffv2.GetPasswordResetReturnProfileRequest
 */
export interface GetPasswordResetReturnProfileRequest {
}
/**
 * @generated from protobuf message enrollment.bffv2.GetPasswordResetReturnProfileResponse
 */
export interface GetPasswordResetReturnProfileResponse {
    /**
     * @generated from protobuf field: string action_name = 1;
     */
    actionName: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.ResendPasswordResetEmailRequest
 */
export interface ResendPasswordResetEmailRequest {
    /**
     * @generated from protobuf field: string email_address = 1;
     */
    emailAddress: string;
    /**
     * @generated from protobuf field: string action_name = 2;
     */
    actionName: string;
    /**
     * @generated from protobuf field: string auth0_id = 3;
     */
    auth0Id: string;
}
/**
 * @generated from protobuf message enrollment.bffv2.ResendPasswordResetEmailResponse
 */
export interface ResendPasswordResetEmailResponse {
}
/**
 * http://hl7.org/fhir/R4/valueset-gender-identity.html
 *
 * @generated from protobuf enum enrollment.bffv2.GenderIdentity
 */
export enum GenderIdentity {
    /**
     * @generated from protobuf enum value: GENDER_IDENTITY_UNSPECIFIED = 0;
     */
    GENDER_IDENTITY_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: MALE = 1;
     */
    MALE = 1,
    /**
     * @generated from protobuf enum value: FEMALE = 2;
     */
    FEMALE = 2,
    /**
     * @generated from protobuf enum value: NON_BINARY = 3;
     */
    NON_BINARY = 3,
    /**
     * @generated from protobuf enum value: TRANSGENDER_MALE = 4;
     */
    TRANSGENDER_MALE = 4,
    /**
     * @generated from protobuf enum value: TRANSGENDER_FEMALE = 5;
     */
    TRANSGENDER_FEMALE = 5,
    /**
     * @generated from protobuf enum value: OTHER = 6;
     */
    OTHER = 6,
    /**
     * @generated from protobuf enum value: NON_DISCLOSE = 7;
     */
    NON_DISCLOSE = 7
}
/**
 * The type of user completing the enrollment flow. Most of the time
 * the user completing the flow is the participant themselves, but there
 * are cases in which the participant is not the one completing the flow, such as
 * when a caregiver completes the flow for a dependent.
 *
 * @generated from protobuf enum enrollment.bffv2.UserType
 */
export enum UserType {
    /**
     * @generated from protobuf enum value: USER_TYPE_UNSPECIFIED = 0;
     */
    USER_TYPE_UNSPECIFIED = 0,
    /**
     * Individual user completing enrollment on their own behalf -- default
     *
     * @generated from protobuf enum value: INDIVIDUAL = 1;
     */
    INDIVIDUAL = 1,
    /**
     * Adult user completing enrollment flow on behalf of a minor
     *
     * @generated from protobuf enum value: PEDIATRIC = 2;
     */
    PEDIATRIC = 2
}
/**
 * @generated from protobuf enum enrollment.bffv2.EnrolleeEvent
 */
export enum EnrolleeEvent {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * Events to fire when pages of enrollment flow are visited.
     *
     * @generated from protobuf enum value: VISIT_PARTICIPANT_DATA = 1;
     */
    VISIT_PARTICIPANT_DATA = 1,
    /**
     * @generated from protobuf enum value: VISIT_INELIGIBLE = 2;
     */
    VISIT_INELIGIBLE = 2,
    /**
     * @generated from protobuf enum value: VISIT_PRODUCT_HANDOFF = 5;
     */
    VISIT_PRODUCT_HANDOFF = 5,
    /**
     * @generated from protobuf enum value: VISIT_SCREENED_OUT = 7;
     */
    VISIT_SCREENED_OUT = 7,
    /**
     * @generated from protobuf enum value: VISIT_ELIGIBILITY = 8;
     */
    VISIT_ELIGIBILITY = 8,
    /**
     * @generated from protobuf enum value: LEAVE_FOR_ECONSENT = 9;
     */
    LEAVE_FOR_ECONSENT = 9,
    /**
     * @generated from protobuf enum value: VISIT_CONSENT_DECLINED = 10;
     */
    VISIT_CONSENT_DECLINED = 10,
    /**
     * @generated from protobuf enum value: VISIT_ELIGIBLE_USER_ALREADY_ENROLLED = 11;
     */
    VISIT_ELIGIBLE_USER_ALREADY_ENROLLED = 11,
    /**
     * Enrollment completion that ends in external redirect
     *
     * @generated from protobuf enum value: VISIT_USER_REDIRECT = 12;
     */
    VISIT_USER_REDIRECT = 12,
    /**
     * @generated from protobuf enum value: VISIT_RECRUIT_INITIALIZATION = 13;
     */
    VISIT_RECRUIT_INITIALIZATION = 13,
    /**
     * @generated from protobuf enum value: VISIT_NUX_INITIALIZATION = 14;
     */
    VISIT_NUX_INITIALIZATION = 14
}
// @generated message type with reflection information, may provide speed optimized methods
class Address$Type extends MessageType<Address> {
    constructor() {
        super("enrollment.bffv2.Address", [
            { no: 1, name: "street_address", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Address>): Address {
        const message = { streetAddress: [], state: "", city: "", zipCode: "", country: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Address>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Address): Address {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string street_address */ 1:
                    message.streetAddress.push(reader.string());
                    break;
                case /* string state */ 2:
                    message.state = reader.string();
                    break;
                case /* string city */ 3:
                    message.city = reader.string();
                    break;
                case /* string zip_code */ 4:
                    message.zipCode = reader.string();
                    break;
                case /* string country */ 5:
                    message.country = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Address, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string street_address = 1; */
        for (let i = 0; i < message.streetAddress.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.streetAddress[i]);
        /* string state = 2; */
        if (message.state !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.state);
        /* string city = 3; */
        if (message.city !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.city);
        /* string zip_code = 4; */
        if (message.zipCode !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.zipCode);
        /* string country = 5; */
        if (message.country !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.country);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.Address
 */
export const Address = new Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Name$Type extends MessageType<Name> {
    constructor() {
        super("enrollment.bffv2.Name", [
            { no: 1, name: "family", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "given", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Name>): Name {
        const message = { family: "", given: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Name>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Name): Name {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string family */ 1:
                    message.family = reader.string();
                    break;
                case /* string given */ 2:
                    message.given = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Name, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string family = 1; */
        if (message.family !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.family);
        /* string given = 2; */
        if (message.given !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.given);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.Name
 */
export const Name = new Name$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Date$Type extends MessageType<Date> {
    constructor() {
        super("enrollment.bffv2.Date", [
            { no: 1, name: "year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "day", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Date>): Date {
        const message = { year: 0, month: 0, day: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Date>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Date): Date {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 year */ 1:
                    message.year = reader.int32();
                    break;
                case /* int32 month */ 2:
                    message.month = reader.int32();
                    break;
                case /* int32 day */ 3:
                    message.day = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Date, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 year = 1; */
        if (message.year !== 0)
            writer.tag(1, WireType.Varint).int32(message.year);
        /* int32 month = 2; */
        if (message.month !== 0)
            writer.tag(2, WireType.Varint).int32(message.month);
        /* int32 day = 3; */
        if (message.day !== 0)
            writer.tag(3, WireType.Varint).int32(message.day);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.Date
 */
export const Date = new Date$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Telecom$Type extends MessageType<Telecom> {
    constructor() {
        super("enrollment.bffv2.Telecom", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "system", kind: "enum", T: () => ["enrollment.bffv2.Telecom.ContactPointSystem", Telecom_ContactPointSystem] }
        ]);
    }
    create(value?: PartialMessage<Telecom>): Telecom {
        const message = { value: "", system: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Telecom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Telecom): Telecom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                case /* enrollment.bffv2.Telecom.ContactPointSystem system */ 2:
                    message.system = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Telecom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        /* enrollment.bffv2.Telecom.ContactPointSystem system = 2; */
        if (message.system !== 0)
            writer.tag(2, WireType.Varint).int32(message.system);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.Telecom
 */
export const Telecom = new Telecom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantData$Type extends MessageType<ParticipantData> {
    constructor() {
        super("enrollment.bffv2.ParticipantData", [
            { no: 1, name: "name", kind: "message", T: () => Name },
            { no: 2, name: "date_of_birth", kind: "message", T: () => Date },
            { no: 3, name: "telecom", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Telecom },
            { no: 4, name: "gender_identity", kind: "enum", T: () => ["enrollment.bffv2.GenderIdentity", GenderIdentity] },
            { no: 5, name: "enrollment_verification_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<ParticipantData>): ParticipantData {
        const message = { telecom: [], genderIdentity: 0, enrollmentVerificationKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantData): ParticipantData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.Name name */ 1:
                    message.name = Name.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* enrollment.bffv2.Date date_of_birth */ 2:
                    message.dateOfBirth = Date.internalBinaryRead(reader, reader.uint32(), options, message.dateOfBirth);
                    break;
                case /* repeated enrollment.bffv2.Telecom telecom */ 3:
                    message.telecom.push(Telecom.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* enrollment.bffv2.GenderIdentity gender_identity */ 4:
                    message.genderIdentity = reader.int32();
                    break;
                case /* string enrollment_verification_key */ 5:
                    message.enrollmentVerificationKey = reader.string();
                    break;
                case /* enrollment.bffv2.Address address */ 6:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipantData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.Name name = 1; */
        if (message.name)
            Name.internalBinaryWrite(message.name, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.Date date_of_birth = 2; */
        if (message.dateOfBirth)
            Date.internalBinaryWrite(message.dateOfBirth, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated enrollment.bffv2.Telecom telecom = 3; */
        for (let i = 0; i < message.telecom.length; i++)
            Telecom.internalBinaryWrite(message.telecom[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.GenderIdentity gender_identity = 4; */
        if (message.genderIdentity !== 0)
            writer.tag(4, WireType.Varint).int32(message.genderIdentity);
        /* string enrollment_verification_key = 5; */
        if (message.enrollmentVerificationKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.enrollmentVerificationKey);
        /* enrollment.bffv2.Address address = 6; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ParticipantData
 */
export const ParticipantData = new ParticipantData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordParticipantDataRequest$Type extends MessageType<RecordParticipantDataRequest> {
    constructor() {
        super("enrollment.bffv2.RecordParticipantDataRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", T: () => ParticipantData },
            { no: 3, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<RecordParticipantDataRequest>): RecordParticipantDataRequest {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordParticipantDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordParticipantDataRequest): RecordParticipantDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                case /* enrollment.bffv2.ParticipantData data */ 2:
                    message.data = ParticipantData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* enrollment.bffv2.Step current_step */ 3:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordParticipantDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        /* enrollment.bffv2.ParticipantData data = 2; */
        if (message.data)
            ParticipantData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.Step current_step = 3; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordParticipantDataRequest
 */
export const RecordParticipantDataRequest = new RecordParticipantDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordParticipantDataResponse$Type extends MessageType<RecordParticipantDataResponse> {
    constructor() {
        super("enrollment.bffv2.RecordParticipantDataResponse", []);
    }
    create(value?: PartialMessage<RecordParticipantDataResponse>): RecordParticipantDataResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordParticipantDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordParticipantDataResponse): RecordParticipantDataResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordParticipantDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordParticipantDataResponse
 */
export const RecordParticipantDataResponse = new RecordParticipantDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormFieldLabelConfig$Type extends MessageType<FormFieldLabelConfig> {
    constructor() {
        super("enrollment.bffv2.FormFieldLabelConfig", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 2, name: "exact_casing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FormFieldLabelConfig>): FormFieldLabelConfig {
        const message = { label: "", exactCasing: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FormFieldLabelConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormFieldLabelConfig): FormFieldLabelConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* bool exact_casing */ 2:
                    message.exactCasing = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FormFieldLabelConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* bool exact_casing = 2; */
        if (message.exactCasing !== false)
            writer.tag(2, WireType.Varint).bool(message.exactCasing);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.FormFieldLabelConfig
 */
export const FormFieldLabelConfig = new FormFieldLabelConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAccountRequest$Type extends MessageType<CreateAccountRequest> {
    constructor() {
        super("enrollment.bffv2.CreateAccountRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "send_password_reset_email", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAccountRequest>): CreateAccountRequest {
        const message = { actionName: "", emailAddress: "", sendPasswordResetEmail: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAccountRequest): CreateAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                case /* string email_address */ 2:
                    message.emailAddress = reader.string();
                    break;
                case /* bool send_password_reset_email */ 3:
                    message.sendPasswordResetEmail = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        /* string email_address = 2; */
        if (message.emailAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.emailAddress);
        /* bool send_password_reset_email = 3; */
        if (message.sendPasswordResetEmail !== false)
            writer.tag(3, WireType.Varint).bool(message.sendPasswordResetEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CreateAccountRequest
 */
export const CreateAccountRequest = new CreateAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAccountResponse$Type extends MessageType<CreateAccountResponse> {
    constructor() {
        super("enrollment.bffv2.CreateAccountResponse", [
            { no: 1, name: "password_reset_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "auth0_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAccountResponse>): CreateAccountResponse {
        const message = { passwordResetUrl: "", auth0Id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAccountResponse): CreateAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string password_reset_url */ 1:
                    message.passwordResetUrl = reader.string();
                    break;
                case /* string auth0_id */ 2:
                    message.auth0Id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string password_reset_url = 1; */
        if (message.passwordResetUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.passwordResetUrl);
        /* string auth0_id = 2; */
        if (message.auth0Id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.auth0Id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CreateAccountResponse
 */
export const CreateAccountResponse = new CreateAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgreementConsentsRequest$Type extends MessageType<GetAgreementConsentsRequest> {
    constructor() {
        super("enrollment.bffv2.GetAgreementConsentsRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<GetAgreementConsentsRequest>): GetAgreementConsentsRequest {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgreementConsentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgreementConsentsRequest): GetAgreementConsentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                case /* enrollment.bffv2.Step current_step */ 2:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgreementConsentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        /* enrollment.bffv2.Step current_step = 2; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetAgreementConsentsRequest
 */
export const GetAgreementConsentsRequest = new GetAgreementConsentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent$Type extends MessageType<AgreementConsentContent> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentContent", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentContent_Module }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent>): AgreementConsentContent {
        const message = { title: "", modules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent): AgreementConsentContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* repeated enrollment.bffv2.AgreementConsentContent.Module modules */ 2:
                    message.modules.push(AgreementConsentContent_Module.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* repeated enrollment.bffv2.AgreementConsentContent.Module modules = 2; */
        for (let i = 0; i < message.modules.length; i++)
            AgreementConsentContent_Module.internalBinaryWrite(message.modules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentContent
 */
export const AgreementConsentContent = new AgreementConsentContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Html$Type extends MessageType<AgreementConsentContent_Html> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentContent.Html", [
            { no: 1, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Html>): AgreementConsentContent_Html {
        const message = { html: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Html>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Html): AgreementConsentContent_Html {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string html */ 1:
                    message.html = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Html, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string html = 1; */
        if (message.html !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.html);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentContent.Html
 */
export const AgreementConsentContent_Html = new AgreementConsentContent_Html$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Links$Type extends MessageType<AgreementConsentContent_Links> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentContent.Links", [
            { no: 1, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentContent_Links_Link }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Links>): AgreementConsentContent_Links {
        const message = { links: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Links>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Links): AgreementConsentContent_Links {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bffv2.AgreementConsentContent.Links.Link links */ 1:
                    message.links.push(AgreementConsentContent_Links_Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Links, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bffv2.AgreementConsentContent.Links.Link links = 1; */
        for (let i = 0; i < message.links.length; i++)
            AgreementConsentContent_Links_Link.internalBinaryWrite(message.links[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentContent.Links
 */
export const AgreementConsentContent_Links = new AgreementConsentContent_Links$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Links_Link$Type extends MessageType<AgreementConsentContent_Links_Link> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentContent.Links.Link", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Links_Link>): AgreementConsentContent_Links_Link {
        const message = { text: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Links_Link>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Links_Link): AgreementConsentContent_Links_Link {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Links_Link, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentContent.Links.Link
 */
export const AgreementConsentContent_Links_Link = new AgreementConsentContent_Links_Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Checkbox$Type extends MessageType<AgreementConsentContent_Checkbox> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentContent.Checkbox", [
            { no: 1, name: "signing_reasons", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Checkbox>): AgreementConsentContent_Checkbox {
        const message = { signingReasons: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Checkbox>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Checkbox): AgreementConsentContent_Checkbox {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string signing_reasons */ 1:
                    message.signingReasons.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Checkbox, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string signing_reasons = 1; */
        for (let i = 0; i < message.signingReasons.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.signingReasons[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentContent.Checkbox
 */
export const AgreementConsentContent_Checkbox = new AgreementConsentContent_Checkbox$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Module$Type extends MessageType<AgreementConsentContent_Module> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentContent.Module", [
            { no: 1, name: "html", kind: "message", oneof: "module", T: () => AgreementConsentContent_Html },
            { no: 2, name: "links", kind: "message", oneof: "module", T: () => AgreementConsentContent_Links },
            { no: 3, name: "checkbox", kind: "message", oneof: "module", T: () => AgreementConsentContent_Checkbox }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Module>): AgreementConsentContent_Module {
        const message = { module: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Module>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Module): AgreementConsentContent_Module {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.AgreementConsentContent.Html html */ 1:
                    message.module = {
                        oneofKind: "html",
                        html: AgreementConsentContent_Html.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).html)
                    };
                    break;
                case /* enrollment.bffv2.AgreementConsentContent.Links links */ 2:
                    message.module = {
                        oneofKind: "links",
                        links: AgreementConsentContent_Links.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).links)
                    };
                    break;
                case /* enrollment.bffv2.AgreementConsentContent.Checkbox checkbox */ 3:
                    message.module = {
                        oneofKind: "checkbox",
                        checkbox: AgreementConsentContent_Checkbox.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).checkbox)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Module, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.AgreementConsentContent.Html html = 1; */
        if (message.module.oneofKind === "html")
            AgreementConsentContent_Html.internalBinaryWrite(message.module.html, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.AgreementConsentContent.Links links = 2; */
        if (message.module.oneofKind === "links")
            AgreementConsentContent_Links.internalBinaryWrite(message.module.links, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.AgreementConsentContent.Checkbox checkbox = 3; */
        if (message.module.oneofKind === "checkbox")
            AgreementConsentContent_Checkbox.internalBinaryWrite(message.module.checkbox, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentContent.Module
 */
export const AgreementConsentContent_Module = new AgreementConsentContent_Module$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgreementConsentsResponse$Type extends MessageType<GetAgreementConsentsResponse> {
    constructor() {
        super("enrollment.bffv2.GetAgreementConsentsResponse", [
            { no: 1, name: "consents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentContent }
        ]);
    }
    create(value?: PartialMessage<GetAgreementConsentsResponse>): GetAgreementConsentsResponse {
        const message = { consents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgreementConsentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgreementConsentsResponse): GetAgreementConsentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bffv2.AgreementConsentContent consents */ 1:
                    message.consents.push(AgreementConsentContent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgreementConsentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bffv2.AgreementConsentContent consents = 1; */
        for (let i = 0; i < message.consents.length; i++)
            AgreementConsentContent.internalBinaryWrite(message.consents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetAgreementConsentsResponse
 */
export const GetAgreementConsentsResponse = new GetAgreementConsentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckEligibilityRequest$Type extends MessageType<CheckEligibilityRequest> {
    constructor() {
        super("enrollment.bffv2.CheckEligibilityRequest", [
            { no: 1, name: "name", kind: "message", T: () => Name },
            { no: 2, name: "date_of_birth", kind: "message", T: () => Date },
            { no: 3, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "enrollment_verification_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<CheckEligibilityRequest>): CheckEligibilityRequest {
        const message = { actionName: "", enrollmentVerificationKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckEligibilityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckEligibilityRequest): CheckEligibilityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.Name name */ 1:
                    message.name = Name.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* enrollment.bffv2.Date date_of_birth */ 2:
                    message.dateOfBirth = Date.internalBinaryRead(reader, reader.uint32(), options, message.dateOfBirth);
                    break;
                case /* string action_name */ 3:
                    message.actionName = reader.string();
                    break;
                case /* string enrollment_verification_key */ 4:
                    message.enrollmentVerificationKey = reader.string();
                    break;
                case /* enrollment.bffv2.Step current_step */ 5:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckEligibilityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.Name name = 1; */
        if (message.name)
            Name.internalBinaryWrite(message.name, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.Date date_of_birth = 2; */
        if (message.dateOfBirth)
            Date.internalBinaryWrite(message.dateOfBirth, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string action_name = 3; */
        if (message.actionName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.actionName);
        /* string enrollment_verification_key = 4; */
        if (message.enrollmentVerificationKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.enrollmentVerificationKey);
        /* enrollment.bffv2.Step current_step = 5; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CheckEligibilityRequest
 */
export const CheckEligibilityRequest = new CheckEligibilityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckEligibilityResponse$Type extends MessageType<CheckEligibilityResponse> {
    constructor() {
        super("enrollment.bffv2.CheckEligibilityResponse", [
            { no: 1, name: "is_eligible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckEligibilityResponse>): CheckEligibilityResponse {
        const message = { isEligible: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckEligibilityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckEligibilityResponse): CheckEligibilityResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_eligible */ 1:
                    message.isEligible = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckEligibilityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_eligible = 1; */
        if (message.isEligible !== false)
            writer.tag(1, WireType.Varint).bool(message.isEligible);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CheckEligibilityResponse
 */
export const CheckEligibilityResponse = new CheckEligibilityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Image$Type extends MessageType<Image> {
    constructor() {
        super("enrollment.bffv2.Image", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "alt_text", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<Image>): Image {
        const message = { uri: "", altText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Image>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Image): Image {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string alt_text */ 2:
                    message.altText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Image, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string alt_text = 2; */
        if (message.altText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.altText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.Image
 */
export const Image = new Image$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileStyleConfig$Type extends MessageType<ProfileStyleConfig> {
    constructor() {
        super("enrollment.bffv2.ProfileStyleConfig", [
            { no: 1, name: "logo_1", kind: "message", T: () => Image },
            { no: 2, name: "logo_2", kind: "message", T: () => Image },
            { no: 3, name: "favicon", kind: "message", T: () => Image },
            { no: 4, name: "disclaimer_html", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 5, name: "product_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 6, name: "primary_color_main", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<ProfileStyleConfig>): ProfileStyleConfig {
        const message = { disclaimerHtml: "", productName: "", primaryColorMain: "", pageTitle: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfileStyleConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfileStyleConfig): ProfileStyleConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.Image logo_1 */ 1:
                    message.logo1 = Image.internalBinaryRead(reader, reader.uint32(), options, message.logo1);
                    break;
                case /* enrollment.bffv2.Image logo_2 */ 2:
                    message.logo2 = Image.internalBinaryRead(reader, reader.uint32(), options, message.logo2);
                    break;
                case /* enrollment.bffv2.Image favicon */ 3:
                    message.favicon = Image.internalBinaryRead(reader, reader.uint32(), options, message.favicon);
                    break;
                case /* string disclaimer_html */ 4:
                    message.disclaimerHtml = reader.string();
                    break;
                case /* string product_name */ 5:
                    message.productName = reader.string();
                    break;
                case /* string primary_color_main */ 6:
                    message.primaryColorMain = reader.string();
                    break;
                case /* string page_title */ 7:
                    message.pageTitle = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfileStyleConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.Image logo_1 = 1; */
        if (message.logo1)
            Image.internalBinaryWrite(message.logo1, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.Image logo_2 = 2; */
        if (message.logo2)
            Image.internalBinaryWrite(message.logo2, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.Image favicon = 3; */
        if (message.favicon)
            Image.internalBinaryWrite(message.favicon, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string disclaimer_html = 4; */
        if (message.disclaimerHtml !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.disclaimerHtml);
        /* string product_name = 5; */
        if (message.productName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.productName);
        /* string primary_color_main = 6; */
        if (message.primaryColorMain !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.primaryColorMain);
        /* string page_title = 7; */
        if (message.pageTitle !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.pageTitle);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ProfileStyleConfig
 */
export const ProfileStyleConfig = new ProfileStyleConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EligibilityConfig$Type extends MessageType<EligibilityConfig> {
    constructor() {
        super("enrollment.bffv2.EligibilityConfig", [
            { no: 1, name: "elig_key_label", kind: "message", T: () => FormFieldLabelConfig },
            { no: 2, name: "elig_check_fields", kind: "enum", opt: true, T: () => ["enrollment.bffv2.EligibilityConfig.EligibilityCheckFields", EligibilityConfig_EligibilityCheckFields] }
        ]);
    }
    create(value?: PartialMessage<EligibilityConfig>): EligibilityConfig {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EligibilityConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EligibilityConfig): EligibilityConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.FormFieldLabelConfig elig_key_label */ 1:
                    message.eligKeyLabel = FormFieldLabelConfig.internalBinaryRead(reader, reader.uint32(), options, message.eligKeyLabel);
                    break;
                case /* optional enrollment.bffv2.EligibilityConfig.EligibilityCheckFields elig_check_fields */ 2:
                    message.eligCheckFields = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EligibilityConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.FormFieldLabelConfig elig_key_label = 1; */
        if (message.eligKeyLabel)
            FormFieldLabelConfig.internalBinaryWrite(message.eligKeyLabel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional enrollment.bffv2.EligibilityConfig.EligibilityCheckFields elig_check_fields = 2; */
        if (message.eligCheckFields !== undefined)
            writer.tag(2, WireType.Varint).int32(message.eligCheckFields);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.EligibilityConfig
 */
export const EligibilityConfig = new EligibilityConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantDataStep$Type extends MessageType<ParticipantDataStep> {
    constructor() {
        super("enrollment.bffv2.ParticipantDataStep", [
            { no: 1, name: "collect_gender_identity", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "collect_address", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "eligibility_config", kind: "message", T: () => EligibilityConfig },
            { no: 4, name: "agreement_consents_config", kind: "message", T: () => AgreementConsentsConfig },
            { no: 5, name: "participant_data_form_header", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 6, name: "participant_data_form_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 7, name: "address_collection_subform_header", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 8, name: "address_collection_subform_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 9, name: "basic_info_subform_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 10, name: "contact_info_subform_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } },
            { no: 11, name: "constraints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ParticipantDataStep_FormFieldConstraint },
            { no: 12, name: "collect_phone", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "collect_email", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "collect_dob", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ParticipantDataStep>): ParticipantDataStep {
        const message = { collectGenderIdentity: false, collectAddress: false, participantDataFormHeader: "", participantDataFormSubheader: "", addressCollectionSubformHeader: "", addressCollectionSubformSubheader: "", basicInfoSubformSubheader: "", contactInfoSubformSubheader: "", constraints: [], collectPhone: false, collectEmail: false, collectDob: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantDataStep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantDataStep): ParticipantDataStep {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool collect_gender_identity */ 1:
                    message.collectGenderIdentity = reader.bool();
                    break;
                case /* bool collect_address */ 2:
                    message.collectAddress = reader.bool();
                    break;
                case /* enrollment.bffv2.EligibilityConfig eligibility_config */ 3:
                    message.eligibilityConfig = EligibilityConfig.internalBinaryRead(reader, reader.uint32(), options, message.eligibilityConfig);
                    break;
                case /* enrollment.bffv2.AgreementConsentsConfig agreement_consents_config */ 4:
                    message.agreementConsentsConfig = AgreementConsentsConfig.internalBinaryRead(reader, reader.uint32(), options, message.agreementConsentsConfig);
                    break;
                case /* string participant_data_form_header */ 5:
                    message.participantDataFormHeader = reader.string();
                    break;
                case /* string participant_data_form_subheader */ 6:
                    message.participantDataFormSubheader = reader.string();
                    break;
                case /* string address_collection_subform_header */ 7:
                    message.addressCollectionSubformHeader = reader.string();
                    break;
                case /* string address_collection_subform_subheader */ 8:
                    message.addressCollectionSubformSubheader = reader.string();
                    break;
                case /* string basic_info_subform_subheader */ 9:
                    message.basicInfoSubformSubheader = reader.string();
                    break;
                case /* string contact_info_subform_subheader */ 10:
                    message.contactInfoSubformSubheader = reader.string();
                    break;
                case /* repeated enrollment.bffv2.ParticipantDataStep.FormFieldConstraint constraints */ 11:
                    message.constraints.push(ParticipantDataStep_FormFieldConstraint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool collect_phone */ 12:
                    message.collectPhone = reader.bool();
                    break;
                case /* bool collect_email */ 13:
                    message.collectEmail = reader.bool();
                    break;
                case /* bool collect_dob */ 14:
                    message.collectDob = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipantDataStep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool collect_gender_identity = 1; */
        if (message.collectGenderIdentity !== false)
            writer.tag(1, WireType.Varint).bool(message.collectGenderIdentity);
        /* bool collect_address = 2; */
        if (message.collectAddress !== false)
            writer.tag(2, WireType.Varint).bool(message.collectAddress);
        /* enrollment.bffv2.EligibilityConfig eligibility_config = 3; */
        if (message.eligibilityConfig)
            EligibilityConfig.internalBinaryWrite(message.eligibilityConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.AgreementConsentsConfig agreement_consents_config = 4; */
        if (message.agreementConsentsConfig)
            AgreementConsentsConfig.internalBinaryWrite(message.agreementConsentsConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string participant_data_form_header = 5; */
        if (message.participantDataFormHeader !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.participantDataFormHeader);
        /* string participant_data_form_subheader = 6; */
        if (message.participantDataFormSubheader !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.participantDataFormSubheader);
        /* string address_collection_subform_header = 7; */
        if (message.addressCollectionSubformHeader !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.addressCollectionSubformHeader);
        /* string address_collection_subform_subheader = 8; */
        if (message.addressCollectionSubformSubheader !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.addressCollectionSubformSubheader);
        /* string basic_info_subform_subheader = 9; */
        if (message.basicInfoSubformSubheader !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.basicInfoSubformSubheader);
        /* string contact_info_subform_subheader = 10; */
        if (message.contactInfoSubformSubheader !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.contactInfoSubformSubheader);
        /* repeated enrollment.bffv2.ParticipantDataStep.FormFieldConstraint constraints = 11; */
        for (let i = 0; i < message.constraints.length; i++)
            ParticipantDataStep_FormFieldConstraint.internalBinaryWrite(message.constraints[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* bool collect_phone = 12; */
        if (message.collectPhone !== false)
            writer.tag(12, WireType.Varint).bool(message.collectPhone);
        /* bool collect_email = 13; */
        if (message.collectEmail !== false)
            writer.tag(13, WireType.Varint).bool(message.collectEmail);
        /* bool collect_dob = 14; */
        if (message.collectDob !== false)
            writer.tag(14, WireType.Varint).bool(message.collectDob);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ParticipantDataStep
 */
export const ParticipantDataStep = new ParticipantDataStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantDataStep_FormFieldConstraint$Type extends MessageType<ParticipantDataStep_FormFieldConstraint> {
    constructor() {
        super("enrollment.bffv2.ParticipantDataStep.FormFieldConstraint", [
            { no: 1, name: "field", kind: "enum", T: () => ["enrollment.bffv2.ParticipantDataStep.Field", ParticipantDataStep_Field] },
            { no: 2, name: "operator", kind: "enum", T: () => ["enrollment.bffv2.ParticipantDataStep.ConstraintOperator", ParticipantDataStep_ConstraintOperator] },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "error_message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<ParticipantDataStep_FormFieldConstraint>): ParticipantDataStep_FormFieldConstraint {
        const message = { field: 0, operator: 0, value: "", errorMessage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantDataStep_FormFieldConstraint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantDataStep_FormFieldConstraint): ParticipantDataStep_FormFieldConstraint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.ParticipantDataStep.Field field */ 1:
                    message.field = reader.int32();
                    break;
                case /* enrollment.bffv2.ParticipantDataStep.ConstraintOperator operator */ 2:
                    message.operator = reader.int32();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* string error_message */ 4:
                    message.errorMessage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipantDataStep_FormFieldConstraint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.ParticipantDataStep.Field field = 1; */
        if (message.field !== 0)
            writer.tag(1, WireType.Varint).int32(message.field);
        /* enrollment.bffv2.ParticipantDataStep.ConstraintOperator operator = 2; */
        if (message.operator !== 0)
            writer.tag(2, WireType.Varint).int32(message.operator);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* string error_message = 4; */
        if (message.errorMessage !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.errorMessage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ParticipantDataStep.FormFieldConstraint
 */
export const ParticipantDataStep_FormFieldConstraint = new ParticipantDataStep_FormFieldConstraint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConfigRequest$Type extends MessageType<GetConfigRequest> {
    constructor() {
        super("enrollment.bffv2.GetConfigRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetConfigRequest>): GetConfigRequest {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConfigRequest): GetConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetConfigRequest
 */
export const GetConfigRequest = new GetConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConfigResponse$Type extends MessageType<GetConfigResponse> {
    constructor() {
        super("enrollment.bffv2.GetConfigResponse", [
            { no: 1, name: "style_config", kind: "message", T: () => ProfileStyleConfig },
            { no: 2, name: "user_type", kind: "enum", T: () => ["enrollment.bffv2.UserType", UserType] },
            { no: 3, name: "step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<GetConfigResponse>): GetConfigResponse {
        const message = { userType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetConfigResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConfigResponse): GetConfigResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.ProfileStyleConfig style_config */ 1:
                    message.styleConfig = ProfileStyleConfig.internalBinaryRead(reader, reader.uint32(), options, message.styleConfig);
                    break;
                case /* enrollment.bffv2.UserType user_type */ 2:
                    message.userType = reader.int32();
                    break;
                case /* enrollment.bffv2.Step step */ 3:
                    message.step = Step.internalBinaryRead(reader, reader.uint32(), options, message.step);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConfigResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.ProfileStyleConfig style_config = 1; */
        if (message.styleConfig)
            ProfileStyleConfig.internalBinaryWrite(message.styleConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.UserType user_type = 2; */
        if (message.userType !== 0)
            writer.tag(2, WireType.Varint).int32(message.userType);
        /* enrollment.bffv2.Step step = 3; */
        if (message.step)
            Step.internalBinaryWrite(message.step, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetConfigResponse
 */
export const GetConfigResponse = new GetConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Step$Type extends MessageType<Step> {
    constructor() {
        super("enrollment.bffv2.Step", [
            { no: 1, name: "account_creation", kind: "message", oneof: "step", T: () => AccountCreationStep },
            { no: 2, name: "participant_data", kind: "message", oneof: "step", T: () => ParticipantDataStep },
            { no: 3, name: "handoff", kind: "message", oneof: "step", T: () => HandoffStep },
            { no: 4, name: "redirect_user", kind: "message", oneof: "step", T: () => RedirectUserStep }
        ]);
    }
    create(value?: PartialMessage<Step>): Step {
        const message = { step: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Step>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Step): Step {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.AccountCreationStep account_creation */ 1:
                    message.step = {
                        oneofKind: "accountCreation",
                        accountCreation: AccountCreationStep.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).accountCreation)
                    };
                    break;
                case /* enrollment.bffv2.ParticipantDataStep participant_data */ 2:
                    message.step = {
                        oneofKind: "participantData",
                        participantData: ParticipantDataStep.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).participantData)
                    };
                    break;
                case /* enrollment.bffv2.HandoffStep handoff */ 3:
                    message.step = {
                        oneofKind: "handoff",
                        handoff: HandoffStep.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).handoff)
                    };
                    break;
                case /* enrollment.bffv2.RedirectUserStep redirect_user */ 4:
                    message.step = {
                        oneofKind: "redirectUser",
                        redirectUser: RedirectUserStep.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).redirectUser)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Step, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.AccountCreationStep account_creation = 1; */
        if (message.step.oneofKind === "accountCreation")
            AccountCreationStep.internalBinaryWrite(message.step.accountCreation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.ParticipantDataStep participant_data = 2; */
        if (message.step.oneofKind === "participantData")
            ParticipantDataStep.internalBinaryWrite(message.step.participantData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.HandoffStep handoff = 3; */
        if (message.step.oneofKind === "handoff")
            HandoffStep.internalBinaryWrite(message.step.handoff, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bffv2.RedirectUserStep redirect_user = 4; */
        if (message.step.oneofKind === "redirectUser")
            RedirectUserStep.internalBinaryWrite(message.step.redirectUser, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.Step
 */
export const Step = new Step$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountCreationStep$Type extends MessageType<AccountCreationStep> {
    constructor() {
        super("enrollment.bffv2.AccountCreationStep", []);
    }
    create(value?: PartialMessage<AccountCreationStep>): AccountCreationStep {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountCreationStep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountCreationStep): AccountCreationStep {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AccountCreationStep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AccountCreationStep
 */
export const AccountCreationStep = new AccountCreationStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandoffStep$Type extends MessageType<HandoffStep> {
    constructor() {
        super("enrollment.bffv2.HandoffStep", [
            { no: 1, name: "app_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "web_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "android_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ios_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HandoffStep>): HandoffStep {
        const message = { appHandoffLink: "", webHandoffLink: "", androidHandoffLink: "", iosHandoffLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HandoffStep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandoffStep): HandoffStep {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string app_handoff_link */ 1:
                    message.appHandoffLink = reader.string();
                    break;
                case /* string web_handoff_link */ 2:
                    message.webHandoffLink = reader.string();
                    break;
                case /* string android_handoff_link */ 3:
                    message.androidHandoffLink = reader.string();
                    break;
                case /* string ios_handoff_link */ 4:
                    message.iosHandoffLink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandoffStep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string app_handoff_link = 1; */
        if (message.appHandoffLink !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.appHandoffLink);
        /* string web_handoff_link = 2; */
        if (message.webHandoffLink !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.webHandoffLink);
        /* string android_handoff_link = 3; */
        if (message.androidHandoffLink !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.androidHandoffLink);
        /* string ios_handoff_link = 4; */
        if (message.iosHandoffLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iosHandoffLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.HandoffStep
 */
export const HandoffStep = new HandoffStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedirectUserStep$Type extends MessageType<RedirectUserStep> {
    constructor() {
        super("enrollment.bffv2.RedirectUserStep", []);
    }
    create(value?: PartialMessage<RedirectUserStep>): RedirectUserStep {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RedirectUserStep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedirectUserStep): RedirectUserStep {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RedirectUserStep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RedirectUserStep
 */
export const RedirectUserStep = new RedirectUserStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentSpecification$Type extends MessageType<ConsentSpecification> {
    constructor() {
        super("enrollment.bffv2.ConsentSpecification", [
            { no: 1, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "consent_revision", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ConsentSpecification>): ConsentSpecification {
        const message = { consentId: "", consentRevision: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentSpecification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentSpecification): ConsentSpecification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_id */ 1:
                    message.consentId = reader.string();
                    break;
                case /* int32 consent_revision */ 2:
                    message.consentRevision = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentSpecification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_id = 1; */
        if (message.consentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentId);
        /* int32 consent_revision = 2; */
        if (message.consentRevision !== 0)
            writer.tag(2, WireType.Varint).int32(message.consentRevision);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ConsentSpecification
 */
export const ConsentSpecification = new ConsentSpecification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentsConfig$Type extends MessageType<AgreementConsentsConfig> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentsConfig", [
            { no: 1, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentsConfig_AgreementConsentsGroup }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentsConfig>): AgreementConsentsConfig {
        const message = { groups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentsConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentsConfig): AgreementConsentsConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bffv2.AgreementConsentsConfig.AgreementConsentsGroup groups */ 1:
                    message.groups.push(AgreementConsentsConfig_AgreementConsentsGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentsConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bffv2.AgreementConsentsConfig.AgreementConsentsGroup groups = 1; */
        for (let i = 0; i < message.groups.length; i++)
            AgreementConsentsConfig_AgreementConsentsGroup.internalBinaryWrite(message.groups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentsConfig
 */
export const AgreementConsentsConfig = new AgreementConsentsConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentsConfig_AgreementConsent$Type extends MessageType<AgreementConsentsConfig_AgreementConsent> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentsConfig.AgreementConsent", [
            { no: 1, name: "consent", kind: "message", T: () => ConsentSpecification },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bffv2.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentsConfig_AgreementConsent>): AgreementConsentsConfig_AgreementConsent {
        const message = { displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentsConfig_AgreementConsent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentsConfig_AgreementConsent): AgreementConsentsConfig_AgreementConsent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.ConsentSpecification consent */ 1:
                    message.consent = ConsentSpecification.internalBinaryRead(reader, reader.uint32(), options, message.consent);
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentsConfig_AgreementConsent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.ConsentSpecification consent = 1; */
        if (message.consent)
            ConsentSpecification.internalBinaryWrite(message.consent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentsConfig.AgreementConsent
 */
export const AgreementConsentsConfig_AgreementConsent = new AgreementConsentsConfig_AgreementConsent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentsConfig_AgreementConsentsGroup$Type extends MessageType<AgreementConsentsConfig_AgreementConsentsGroup> {
    constructor() {
        super("enrollment.bffv2.AgreementConsentsConfig.AgreementConsentsGroup", [
            { no: 1, name: "consents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentsConfig_AgreementConsent }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentsConfig_AgreementConsentsGroup>): AgreementConsentsConfig_AgreementConsentsGroup {
        const message = { consents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentsConfig_AgreementConsentsGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentsConfig_AgreementConsentsGroup): AgreementConsentsConfig_AgreementConsentsGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bffv2.AgreementConsentsConfig.AgreementConsent consents */ 1:
                    message.consents.push(AgreementConsentsConfig_AgreementConsent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentsConfig_AgreementConsentsGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bffv2.AgreementConsentsConfig.AgreementConsent consents = 1; */
        for (let i = 0; i < message.consents.length; i++)
            AgreementConsentsConfig_AgreementConsent.internalBinaryWrite(message.consents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.AgreementConsentsConfig.AgreementConsentsGroup
 */
export const AgreementConsentsConfig_AgreementConsentsGroup = new AgreementConsentsConfig_AgreementConsentsGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteEnrollmentRequest$Type extends MessageType<CompleteEnrollmentRequest> {
    constructor() {
        super("enrollment.bffv2.CompleteEnrollmentRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteEnrollmentRequest>): CompleteEnrollmentRequest {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteEnrollmentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteEnrollmentRequest): CompleteEnrollmentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteEnrollmentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CompleteEnrollmentRequest
 */
export const CompleteEnrollmentRequest = new CompleteEnrollmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteEnrollmentResponse$Type extends MessageType<CompleteEnrollmentResponse> {
    constructor() {
        super("enrollment.bffv2.CompleteEnrollmentResponse", []);
    }
    create(value?: PartialMessage<CompleteEnrollmentResponse>): CompleteEnrollmentResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteEnrollmentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteEnrollmentResponse): CompleteEnrollmentResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CompleteEnrollmentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CompleteEnrollmentResponse
 */
export const CompleteEnrollmentResponse = new CompleteEnrollmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordEnrolleeEventRequest$Type extends MessageType<RecordEnrolleeEventRequest> {
    constructor() {
        super("enrollment.bffv2.RecordEnrolleeEventRequest", [
            { no: 1, name: "observed_at", kind: "message", T: () => Timestamp },
            { no: 2, name: "observed_time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event", kind: "enum", T: () => ["enrollment.bffv2.EnrolleeEvent", EnrolleeEvent] }
        ]);
    }
    create(value?: PartialMessage<RecordEnrolleeEventRequest>): RecordEnrolleeEventRequest {
        const message = { observedTimeZone: "", event: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordEnrolleeEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordEnrolleeEventRequest): RecordEnrolleeEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp observed_at */ 1:
                    message.observedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.observedAt);
                    break;
                case /* string observed_time_zone */ 2:
                    message.observedTimeZone = reader.string();
                    break;
                case /* enrollment.bffv2.EnrolleeEvent event */ 3:
                    message.event = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordEnrolleeEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp observed_at = 1; */
        if (message.observedAt)
            Timestamp.internalBinaryWrite(message.observedAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string observed_time_zone = 2; */
        if (message.observedTimeZone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.observedTimeZone);
        /* enrollment.bffv2.EnrolleeEvent event = 3; */
        if (message.event !== 0)
            writer.tag(3, WireType.Varint).int32(message.event);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordEnrolleeEventRequest
 */
export const RecordEnrolleeEventRequest = new RecordEnrolleeEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordEnrolleeEventResponse$Type extends MessageType<RecordEnrolleeEventResponse> {
    constructor() {
        super("enrollment.bffv2.RecordEnrolleeEventResponse", []);
    }
    create(value?: PartialMessage<RecordEnrolleeEventResponse>): RecordEnrolleeEventResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordEnrolleeEventResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordEnrolleeEventResponse): RecordEnrolleeEventResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordEnrolleeEventResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordEnrolleeEventResponse
 */
export const RecordEnrolleeEventResponse = new RecordEnrolleeEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordUTMRequest$Type extends MessageType<RecordUTMRequest> {
    constructor() {
        super("enrollment.bffv2.RecordUTMRequest", [
            { no: 1, name: "utm_tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RecordUTMRequest_UTMTag },
            { no: 2, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecordUTMRequest>): RecordUTMRequest {
        const message = { utmTags: [], actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordUTMRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordUTMRequest): RecordUTMRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bffv2.RecordUTMRequest.UTMTag utm_tags */ 1:
                    message.utmTags.push(RecordUTMRequest_UTMTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string action_name */ 2:
                    message.actionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordUTMRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bffv2.RecordUTMRequest.UTMTag utm_tags = 1; */
        for (let i = 0; i < message.utmTags.length; i++)
            RecordUTMRequest_UTMTag.internalBinaryWrite(message.utmTags[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string action_name = 2; */
        if (message.actionName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.actionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordUTMRequest
 */
export const RecordUTMRequest = new RecordUTMRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordUTMRequest_UTMTag$Type extends MessageType<RecordUTMRequest_UTMTag> {
    constructor() {
        super("enrollment.bffv2.RecordUTMRequest.UTMTag", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecordUTMRequest_UTMTag>): RecordUTMRequest_UTMTag {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordUTMRequest_UTMTag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordUTMRequest_UTMTag): RecordUTMRequest_UTMTag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordUTMRequest_UTMTag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordUTMRequest.UTMTag
 */
export const RecordUTMRequest_UTMTag = new RecordUTMRequest_UTMTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordUTMResponse$Type extends MessageType<RecordUTMResponse> {
    constructor() {
        super("enrollment.bffv2.RecordUTMResponse", []);
    }
    create(value?: PartialMessage<RecordUTMResponse>): RecordUTMResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordUTMResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordUTMResponse): RecordUTMResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordUTMResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.RecordUTMResponse
 */
export const RecordUTMResponse = new RecordUTMResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartEnrollmentRequest$Type extends MessageType<StartEnrollmentRequest> {
    constructor() {
        super("enrollment.bffv2.StartEnrollmentRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartEnrollmentRequest>): StartEnrollmentRequest {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartEnrollmentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartEnrollmentRequest): StartEnrollmentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartEnrollmentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.StartEnrollmentRequest
 */
export const StartEnrollmentRequest = new StartEnrollmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartEnrollmentResponse$Type extends MessageType<StartEnrollmentResponse> {
    constructor() {
        super("enrollment.bffv2.StartEnrollmentResponse", []);
    }
    create(value?: PartialMessage<StartEnrollmentResponse>): StartEnrollmentResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartEnrollmentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartEnrollmentResponse): StartEnrollmentResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StartEnrollmentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.StartEnrollmentResponse
 */
export const StartEnrollmentResponse = new StartEnrollmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStateRequest$Type extends MessageType<GetUserStateRequest> {
    constructor() {
        super("enrollment.bffv2.GetUserStateRequest", []);
    }
    create(value?: PartialMessage<GetUserStateRequest>): GetUserStateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserStateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserStateRequest): GetUserStateRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetUserStateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetUserStateRequest
 */
export const GetUserStateRequest = new GetUserStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStateResponse$Type extends MessageType<GetUserStateResponse> {
    constructor() {
        super("enrollment.bffv2.GetUserStateResponse", [
            { no: 1, name: "participant_data", kind: "message", T: () => ParticipantData },
            { no: 5, name: "is_complete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "patient_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserStateResponse>): GetUserStateResponse {
        const message = { isComplete: false, patientId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserStateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserStateResponse): GetUserStateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bffv2.ParticipantData participant_data */ 1:
                    message.participantData = ParticipantData.internalBinaryRead(reader, reader.uint32(), options, message.participantData);
                    break;
                case /* bool is_complete */ 5:
                    message.isComplete = reader.bool();
                    break;
                case /* string patient_id */ 6:
                    message.patientId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserStateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bffv2.ParticipantData participant_data = 1; */
        if (message.participantData)
            ParticipantData.internalBinaryWrite(message.participantData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool is_complete = 5; */
        if (message.isComplete !== false)
            writer.tag(5, WireType.Varint).bool(message.isComplete);
        /* string patient_id = 6; */
        if (message.patientId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.patientId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetUserStateResponse
 */
export const GetUserStateResponse = new GetUserStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartSessionRequest$Type extends MessageType<StartSessionRequest> {
    constructor() {
        super("enrollment.bffv2.StartSessionRequest", [
            { no: 4, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "query_params", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StartSessionRequest_QueryParam },
            { no: 2, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[+-](?:2[0-3]|[01][0-9]):[0-5][0-9]$" } } } }
        ]);
    }
    create(value?: PartialMessage<StartSessionRequest>): StartSessionRequest {
        const message = { actionName: "", queryParams: [], timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartSessionRequest): StartSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 4:
                    message.actionName = reader.string();
                    break;
                case /* repeated enrollment.bffv2.StartSessionRequest.QueryParam query_params */ 1:
                    message.queryParams.push(StartSessionRequest_QueryParam.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string time_zone */ 2:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 4; */
        if (message.actionName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.actionName);
        /* repeated enrollment.bffv2.StartSessionRequest.QueryParam query_params = 1; */
        for (let i = 0; i < message.queryParams.length; i++)
            StartSessionRequest_QueryParam.internalBinaryWrite(message.queryParams[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string time_zone = 2; */
        if (message.timeZone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.StartSessionRequest
 */
export const StartSessionRequest = new StartSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartSessionRequest_QueryParam$Type extends MessageType<StartSessionRequest_QueryParam> {
    constructor() {
        super("enrollment.bffv2.StartSessionRequest.QueryParam", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartSessionRequest_QueryParam>): StartSessionRequest_QueryParam {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartSessionRequest_QueryParam>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartSessionRequest_QueryParam): StartSessionRequest_QueryParam {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartSessionRequest_QueryParam, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.StartSessionRequest.QueryParam
 */
export const StartSessionRequest_QueryParam = new StartSessionRequest_QueryParam$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartSessionResponse$Type extends MessageType<StartSessionResponse> {
    constructor() {
        super("enrollment.bffv2.StartSessionResponse", []);
    }
    create(value?: PartialMessage<StartSessionResponse>): StartSessionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartSessionResponse): StartSessionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StartSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.StartSessionResponse
 */
export const StartSessionResponse = new StartSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportErrorRequest$Type extends MessageType<ReportErrorRequest> {
    constructor() {
        super("enrollment.bffv2.ReportErrorRequest", [
            { no: 1, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "stack", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReportErrorRequest>): ReportErrorRequest {
        const message = { location: "", message: "", stack: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportErrorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportErrorRequest): ReportErrorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string location */ 1:
                    message.location = reader.string();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* string stack */ 3:
                    message.stack = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportErrorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string location = 1; */
        if (message.location !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.location);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* string stack = 3; */
        if (message.stack !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stack);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ReportErrorRequest
 */
export const ReportErrorRequest = new ReportErrorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportErrorResponse$Type extends MessageType<ReportErrorResponse> {
    constructor() {
        super("enrollment.bffv2.ReportErrorResponse", []);
    }
    create(value?: PartialMessage<ReportErrorResponse>): ReportErrorResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportErrorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportErrorResponse): ReportErrorResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReportErrorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ReportErrorResponse
 */
export const ReportErrorResponse = new ReportErrorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthenticationRequest$Type extends MessageType<CheckAuthenticationRequest> {
    constructor() {
        super("enrollment.bffv2.CheckAuthenticationRequest", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<CheckAuthenticationRequest>): CheckAuthenticationRequest {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckAuthenticationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthenticationRequest): CheckAuthenticationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                case /* enrollment.bffv2.Step current_step */ 2:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckAuthenticationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        /* enrollment.bffv2.Step current_step = 2; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CheckAuthenticationRequest
 */
export const CheckAuthenticationRequest = new CheckAuthenticationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthenticationResponse$Type extends MessageType<CheckAuthenticationResponse> {
    constructor() {
        super("enrollment.bffv2.CheckAuthenticationResponse", []);
    }
    create(value?: PartialMessage<CheckAuthenticationResponse>): CheckAuthenticationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckAuthenticationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthenticationResponse): CheckAuthenticationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CheckAuthenticationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.CheckAuthenticationResponse
 */
export const CheckAuthenticationResponse = new CheckAuthenticationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordResetReturnProfileRequest$Type extends MessageType<GetPasswordResetReturnProfileRequest> {
    constructor() {
        super("enrollment.bffv2.GetPasswordResetReturnProfileRequest", []);
    }
    create(value?: PartialMessage<GetPasswordResetReturnProfileRequest>): GetPasswordResetReturnProfileRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordResetReturnProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordResetReturnProfileRequest): GetPasswordResetReturnProfileRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPasswordResetReturnProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetPasswordResetReturnProfileRequest
 */
export const GetPasswordResetReturnProfileRequest = new GetPasswordResetReturnProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordResetReturnProfileResponse$Type extends MessageType<GetPasswordResetReturnProfileResponse> {
    constructor() {
        super("enrollment.bffv2.GetPasswordResetReturnProfileResponse", [
            { no: 1, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPasswordResetReturnProfileResponse>): GetPasswordResetReturnProfileResponse {
        const message = { actionName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordResetReturnProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordResetReturnProfileResponse): GetPasswordResetReturnProfileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_name */ 1:
                    message.actionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPasswordResetReturnProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string action_name = 1; */
        if (message.actionName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.GetPasswordResetReturnProfileResponse
 */
export const GetPasswordResetReturnProfileResponse = new GetPasswordResetReturnProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResendPasswordResetEmailRequest$Type extends MessageType<ResendPasswordResetEmailRequest> {
    constructor() {
        super("enrollment.bffv2.ResendPasswordResetEmailRequest", [
            { no: 1, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "auth0_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<ResendPasswordResetEmailRequest>): ResendPasswordResetEmailRequest {
        const message = { emailAddress: "", actionName: "", auth0Id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResendPasswordResetEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResendPasswordResetEmailRequest): ResendPasswordResetEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email_address */ 1:
                    message.emailAddress = reader.string();
                    break;
                case /* string action_name */ 2:
                    message.actionName = reader.string();
                    break;
                case /* string auth0_id */ 3:
                    message.auth0Id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResendPasswordResetEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email_address = 1; */
        if (message.emailAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.emailAddress);
        /* string action_name = 2; */
        if (message.actionName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.actionName);
        /* string auth0_id = 3; */
        if (message.auth0Id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.auth0Id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ResendPasswordResetEmailRequest
 */
export const ResendPasswordResetEmailRequest = new ResendPasswordResetEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResendPasswordResetEmailResponse$Type extends MessageType<ResendPasswordResetEmailResponse> {
    constructor() {
        super("enrollment.bffv2.ResendPasswordResetEmailResponse", []);
    }
    create(value?: PartialMessage<ResendPasswordResetEmailResponse>): ResendPasswordResetEmailResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResendPasswordResetEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResendPasswordResetEmailResponse): ResendPasswordResetEmailResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResendPasswordResetEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bffv2.ResendPasswordResetEmailResponse
 */
export const ResendPasswordResetEmailResponse = new ResendPasswordResetEmailResponse$Type();
/**
 * @generated ServiceType for protobuf service enrollment.bffv2.Enrollment
 */
export const Enrollment = new ServiceType("enrollment.bffv2.Enrollment", [
    { name: "StartSession", options: {}, I: StartSessionRequest, O: StartSessionResponse },
    { name: "CreateAccount", options: {}, I: CreateAccountRequest, O: CreateAccountResponse },
    { name: "ResendPasswordResetEmail", options: {}, I: ResendPasswordResetEmailRequest, O: ResendPasswordResetEmailResponse },
    { name: "GetAgreementConsents", options: {}, I: GetAgreementConsentsRequest, O: GetAgreementConsentsResponse },
    { name: "CheckEligibility", options: {}, I: CheckEligibilityRequest, O: CheckEligibilityResponse },
    { name: "CompleteEnrollment", options: {}, I: CompleteEnrollmentRequest, O: CompleteEnrollmentResponse },
    { name: "GetConfig", options: {}, I: GetConfigRequest, O: GetConfigResponse },
    { name: "RecordEnrolleeEvent", options: {}, I: RecordEnrolleeEventRequest, O: RecordEnrolleeEventResponse },
    { name: "RecordUTM", options: {}, I: RecordUTMRequest, O: RecordUTMResponse },
    { name: "StartEnrollment", options: {}, I: StartEnrollmentRequest, O: StartEnrollmentResponse },
    { name: "GetUserState", options: {}, I: GetUserStateRequest, O: GetUserStateResponse },
    { name: "ReportError", options: {}, I: ReportErrorRequest, O: ReportErrorResponse },
    { name: "CheckAuthentication", options: {}, I: CheckAuthenticationRequest, O: CheckAuthenticationResponse },
    { name: "GetPasswordResetReturnProfile", options: {}, I: GetPasswordResetReturnProfileRequest, O: GetPasswordResetReturnProfileResponse },
    { name: "ForceLogin", options: {}, I: Empty, O: Empty }
]);
