import {Stack, useTheme} from '@mui/material';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {Checkbox, OpenInNewIcon} from '@verily-src/react-design-system';
import {AgreementConsentContent} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {Controller, useFormContext} from 'react-hook-form';

/**
 * Assumes that the consent argument:
 * a) has exactly one LinkModule and one CheckboxModule, and ignores all other content.
 * b) has as many links in the LinkModule as there are signing reasons in the CheckboxModule, and that this number is larger than zero.
 */
export default function ConsentCheckboxLayout(
  consent: AgreementConsentContent,
  idx: number
) {
  const {control} = useFormContext();
  const theme = useTheme();
  const linkModule = consent.modules.find(module => {
    return module.module.oneofKind === 'links';
  });
  if (!linkModule) {
    return;
  }
  const consentLinks = linkModule.module['links'].links;
  if (!consentLinks) {
    return;
  }
  const checkboxModule = consent.modules.find(module => {
    return module.module.oneofKind === 'checkbox';
  });
  if (!checkboxModule) {
    return;
  }
  const signingReasons = checkboxModule.module['checkbox'].signingReasons;
  if (!signingReasons) {
    return;
  }
  if (
    phafConfig.getBoolean('FEATURE_NUX_TOS_ENABLED') &&
    consentLinks.length !== signingReasons.length
  ) {
    throw new Error('The number of links and signing reasons must be equal.');
  }
  return (
    <Stack direction="column" key={idx} spacing={1}>
      {consentLinks.map((link, linkIdx) => (
        <Controller
          key={`checkboxReasons.${idx}.${linkIdx}`}
          name={`checkboxReasons.${idx}.${linkIdx}`}
          control={control}
          render={({field: {ref: _, ...field}}) => (
            <Checkbox
              data-cy="policy-checkbox"
              data-testid="policy-checkbox"
              {...field}
              checked={field.value || false}
              label={
                phafConfig.getBoolean('FEATURE_NUX_TOS_ENABLED')
                  ? signingReasons[linkIdx]
                  : null
              }
              customLabelSx={{
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
              }}
              optionLabel={
                phafConfig.getBoolean('FEATURE_NUX_TOS_ENABLED')
                  ? link.text
                  : signingReasons[linkIdx]
              }
              customOptionLabelSx={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: 'bold',
              }}
              rightIcon={<OpenInNewIcon />}
              rightIconLink={link.url}
            />
          )}
        />
      ))}
    </Stack>
  );
}
