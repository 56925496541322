import {Box} from '@mui/material';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  ConsumerLightTheme,
  ConsumerModernLightTheme,
} from '@verily-src/react-design-system';

type AccountCreationBoxProps = {
  children: React.ReactNode;
};

export default function AccountCreationBox({
  children,
}: AccountCreationBoxProps) {
  const modernThemeEnabled = phafConfig.getBoolean(
    'FEATURE_MODERN_THEME_ENABLED'
  );
  const rdsTheme = modernThemeEnabled
    ? ConsumerModernLightTheme
    : ConsumerLightTheme;

  return (
    <Box
      style={{
        backgroundColor: rdsTheme?.palette?.background?.canvas,
        width: '100vw',
        height: '100vh',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        alignItems="center"
        style={{
          backgroundColor: rdsTheme?.palette?.background?.paper,
          borderRadius: '8px',
          overflowWrap: 'anywhere',
          width: '400px',
          padding: '40px 20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
