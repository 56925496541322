import {yupResolver} from '@hookform/resolvers/yup';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useSnackbar} from '@verily-src/react-design-system';
import {
  AgreementConsentContent,
  EnrolleeEvent,
  GetAgreementConsentsResponse,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {TFunction} from 'i18next';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {useCheckEligibility} from '../../hooks/useCheckEligibility';
import {useConfig} from '../../hooks/useConfig';
import {useDomain} from '../../hooks/useDomain';
import {useProfile} from '../../hooks/useProfile';
import {useRecordEvent} from '../../hooks/useRecordEvent';
import {useUserState} from '../../hooks/useUserState';
import {EnrollmentError, EnrollmentErrorType} from '../../lib/api/error';
import {getAgreementConsents} from '../../lib/api/getAgreementConsents';
import {toNameProto} from '../../lib/proto/conversion';
import {EligibilityStatus} from '../../lib/types/userEligibility';
import {UserState} from '../../lib/types/userState';
import {EnrollmentStep} from '../../types/flow';
import {errorPath} from '../../types/route';
import {
  isValidFhirSearchString,
  numCheckboxesInConsents,
  policiesAgreedTo,
} from '../../utils';
import {useProgress} from '../enrollment-flow/progress';
import Loading from '../loading';
import {getSchema} from '../policy';
import {TerminationReason} from '../termination-page/states';
import ParticipantDataLayout from './layout';

export const createSchema = (t: TFunction) => {
  const nameFields = phafConfig.getBoolean('FEATURE_NUX_TOS_ENABLED')
    ? {}
    : {
        firstName: yup
          .string()
          .trim()
          .required(t('eligibility.please-enter-your-first-name'))
          .test(
            'firstname-valid-chars',
            t('patientData.invalid-first-name'),
            (value, _context) => {
              return isValidFhirSearchString(value);
            }
          )
          .default(''),
        lastName: yup
          .string()
          .trim()
          .required(t('eligibility.please-enter-your-last-name'))
          .test(
            'lastName-valid-chars',
            t('patientData.invalid-last-name'),
            (value, _context) => {
              return isValidFhirSearchString(value);
            }
          )
          .default(''),
      };

  return yup
    .object({
      ...nameFields,
      ...getSchema().fields,
    })
    .required();
};

export default function ParticipantDataNUXPage() {
  const {config} = useConfig();
  const participantDataStep = config.flow.enrollmentStepToConfigStep.get(
    EnrollmentStep.PARTICIPANT_DATA
  );
  const {checkEligibility} = useCheckEligibility(participantDataStep);
  const {userState, setParticipantData} = useUserState(participantDataStep);
  const {t} = useTranslation();
  const {recordEvent} = useRecordEvent();
  const {completeStep, failStep} = useProgress(
    EnrollmentStep.PARTICIPANT_DATA_NUX
  );
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [consentsAreLoaded, setConsentsAreLoaded] = useState(
    !config.hasAgreementConsents
  );
  const [consents, setConsents] = useState<AgreementConsentContent[]>([]);

  useEffect(() => {
    if (!config.hasAgreementConsents) {
      return;
    }
    getAgreementConsents(profileName, domainName, participantDataStep)
      .then((res: GetAgreementConsentsResponse) => {
        setConsents(res.consents);
        setConsentsAreLoaded(true);
      })
      .catch(() => {
        navigate(errorPath());
      });
  }, [
    profileName,
    domainName,
    config.hasAgreementConsents,
    navigate,
    participantDataStep,
  ]);

  useEffect(() => {
    recordEvent(EnrolleeEvent.VISIT_NUX_PATIENT_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = createSchema(t);

  type SchemaType = yup.InferType<typeof schema>;

  const useFormMethods = useForm<SchemaType>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values: {
      ...schema.getDefault(),
      ...userState?.['participantData'],
    },
  });

  const [currentlyLoading, setCurrentlyLoading] = useState(false);

  const submit = async () => {
    setCurrentlyLoading(true);

    // Fetch all form values excluding checkboxReasons.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {checkboxReasons, ...formValues} = useFormMethods.getValues();

    const participantData: UserState['participantData'] = {
      ...formValues,
    } as UserState['participantData'];

    if (config.eligibility.checkEligibility) {
      // Participant Data NUX can support only email-based eligibility checks,
      // since it is not designed to support the option of an enrollment
      // verification key input field. For this reason, eligibility cannot be
      // retried - the user can't correct the information (the email associated
      // with their Auth0 account) they're using.
      let eligibilityStatus: EligibilityStatus;
      try {
        eligibilityStatus = await checkEligibility({
          name: phafConfig.getBoolean('FEATURE_NUX_TOS_ENABLED')
            ? null
            : toNameProto(participantData.firstName, participantData.lastName),
          dob: null,
          enrollmentVerificationKey: null,
        });
      } catch (error) {
        if (error instanceof EnrollmentError) {
          switch (error.errorType) {
            case EnrollmentErrorType.MISCONFIGURED_ELIGIBILITY_GROUP:
              snackbar.show(
                t('snackbar.misconfigured-eligibility-group'),
                'error'
              );
              break;
            case EnrollmentErrorType.ELIGIBILITY_USER_ALREADY_ENROLLED:
              failStep(TerminationReason.ELIGIBILITY_USER_ALREADY_ENROLLED);
              break;
            default:
              snackbar.show(t('snackbar.eligibility-check-error'), 'error');
              break;
          }
          setCurrentlyLoading(false);
          return;
        }
        setCurrentlyLoading(false);
        snackbar.show(t('snackbar.eligibility-check-error'), 'error');
        return;
      }
      if (!eligibilityStatus.isEligible) {
        failStep(TerminationReason.INELIGIBLE);
        return;
      }
    }
    try {
      const consentData = config.hasAgreementConsents
        ? {
            policySetConsents: [
              ...Array(numCheckboxesInConsents(consents)).keys(),
            ].map(_ => new Date()),
          }
        : undefined;
      await setParticipantData!(participantData, consentData);
    } catch (error) {
      snackbar.show(t('snackbar.enroll-submit-error'), 'error');
      setCurrentlyLoading(false);
      return;
    }

    setCurrentlyLoading(false);
    completeStep();
  };

  if (consentsAreLoaded && !currentlyLoading) {
    return (
      <FormProvider {...useFormMethods}>
        <ParticipantDataLayout
          submit={useFormMethods.handleSubmit(submit)}
          consents={consents}
          policiesAgreedTo={policiesAgreedTo(config, userState)}
          userType={config.userType}
        />
      </FormProvider>
    );
  } else {
    return <Loading />;
  }
}
